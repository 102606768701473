import { Avatar, Box, Checkbox, Container, Divider, FormControl, FormControlLabel, InputLabel, Link, Radio, RadioGroup, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackMessage, { SnackMessageContent } from "../shared/snackMessage";
import { FormPageResponse, PageInfo } from "../../models/formPageResponse";
import AuthContext, { Auth } from "../../contexts/authContext";
import { ChangeEvent, useContext, useEffect, useState } from "react";

import styles from './biometricConsent.module.css';
import Footer from "../footer/footer";
import { Loading } from "../shared/loadingWheelOverlay";
import { ErrorDetail } from "../error/error";
import { ApiResponse } from "../../models/apiResponse";
import { LoadingState } from "../../models/loadingState";
import { ApiErrorCode } from "../../enums/ApiErrorCode";
import { ErrorPageTitle } from "../constants/errorMessages";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '../shared/Accordian';
import FormContext, { FormState } from "../../contexts/formContext";
import { Routes } from "../../enums/routes";
import ConsentPlaceholder from "../mediaConsent/components/consentPlaceholder";
import { getBiometricConsentDetailsAsync, submitBiometricConsentAsync } from "../../services/forms-api-service";
import { BiometricConsentFormData, BiometricConsentRequest } from "../../models/biometricConsent";
import { Formik } from "formik";
import { biometricConsentValidationSchema } from "../biometricConsent/biometricConsentSchema";
import { PixSpan } from "../shared/styledComponents";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { RelationshipTypes } from "../constants/constants";
import configuration from "../../config";

const defaultImage = require('../../assets/profile-bg.svg').default;


const BiometricConsent = () => {

    const navigate = useNavigate();

    const { user } = useContext<Auth>(AuthContext);
    const { formInfo: { galleryName, personName }, setFormInfo } = useContext<FormState>(FormContext)

    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [isLoading, setLoading] = useState<LoadingState>({ showSkeleton: false, showSpinner: true });
    const [skipComponent, setSkipComponent] = useState<boolean>(false);
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: "info" });
    const [expanded, setExpanded] = useState<string | false>('informationPanel');

    const [consentFormData, setFormDetails] = useState<BiometricConsentFormData | null>();
    const [requestData, setFormData] = useState<BiometricConsentRequest>(
        {
            inviteUid: "",
            consentAccepted: false,
            dateAccepted: new Date(),
            isPermissionsAgreed: false,
            providerRelationship: "",
        }
    );

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    const handleSelectChange = (event: SelectChangeEvent) => {
        setFormData(prevData => ({
            ...prevData,
            providerRelationship: event.target.value
        }));
    };
    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.BiometricConsent
        }));
    }, [])


    useEffect(() => {
        if (user?.inviteUid && user?.inviteUid.length > 0) {
            //Update the form data with the inviteUid.
            setFormData(prevFormData => ({
                ...prevFormData,
                inviteUid: user.inviteUid!
            }));
            getBiometricConsentDetails();
        }
        return () => { abortController.abort() };
    }, [user?.inviteUid]);


    /**
     * Get the form data required to display the compoinent page
     * @returns 
     */
    const getBiometricConsentDetails = async () => {
        try {
            setLoading({ ...isLoading, showSkeleton: true });

            // Request consent details from the API
            const response: ApiResponse<FormPageResponse<BiometricConsentFormData>> = await getBiometricConsentDetailsAsync(user!.inviteUid!, abortSignal);

            if (response.errorCode === ApiErrorCode.FatalError) {
                // If we have a fatal error, navigate to the error page.
                return navigate('/error');
            }

            if (response.success) {
                // Update the component state with the consent details and page info
                const data = response.data!;
                setPageInfo(new PageInfo(data.nextPageRoute, data.prevPageRoute, response.errorMessage))
                setFormDetails(data.formData);
            } else {
                setSkipComponent(true);
            }
            setLoading({ showSkeleton: false, showSpinner: false });

        } catch (error: any) {
            console.error("ERROR retrieving consent details", error);
            navigate('/error');
        }
    }

    /**
     * Submit the biometric consent form to the Forms API.
     * @param formData 
     * @returns 
     */
    const submitBiometricConsent = async (formData: BiometricConsentRequest) => {
        try {

            //if the consent panel is not open, just open it rather than submit
            if (expanded === 'informationPanel' || expanded === false) {
                setExpanded('consentPanel');
                return;
            }

            if (!user?.inviteUid) {
                console.error("InviteUid is required for this form component.");
                navigate('/error');
            }

            setLoading({ ...isLoading, showSkeleton: false, showSpinner: true });
            let response = await submitBiometricConsentAsync(formData);
            if (!response.success) {
                return handleError(response);

            }
            // Show success message and navigate to next page
            setSnackMessage({ message: 'Submitted Biometric Consent', messageLevel: 'success' })
            setSnackOpen(true);
            navigate(pageInfo.nextPageRoute);

        } catch (err) {
            console.log("ERROR submitting biometric consent.", err);
            navigate('/error');
        }
    }

    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
        } else {
            navigate('/error');
        }
    }

    const handleConsentRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData(prevData => ({
            ...prevData,
            consentAccepted: str2bool(event.target.value)
        }))
    }

    var str2bool = (value: any): boolean => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }


    const handlePersonAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevData => ({
            ...prevData,
            isPermissionsAgreed: event.target.checked
        }))
    }

    const handlePanelChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * Click event handler for the previous navigation button.
     */
    const onClickPrevious = () => {
        if (pageInfo.prevPageRoute) {
            navigate(pageInfo.prevPageRoute);
        }
    };


    if (skipComponent) {
        return (
            <div className={styles.centerContent}>
                <Typography gutterBottom variant="h3" component="div" textAlign="center" color="primary" >
                    Page is not available.
                </Typography>

                <Typography gutterBottom variant="h6" component="div" textAlign="center" fontWeight='fontWeightLight' >
                    {pageInfo.message}
                </Typography>
                <Footer
                    onNext={() => navigate(pageInfo.nextPageRoute!)}
                    onPrev={onClickPrevious}
                    nextText={pageInfo.isFinalPage ? 'Finish' : 'Skip'}
                    showPrev={pageInfo.prevPageRoute !== null && pageInfo.prevPageRoute !== undefined}
                />
            </div>
        )
    } else {
        return (
            <Container>
                <Loading isLoading={isLoading.showSpinner} />
                <h1 className={styles.centerText}>Biometric Consent</h1>
                {isLoading.showSkeleton ? (
                    <ConsentPlaceholder lines={6} />
                ) : (
                    <>
                        <Accordion expanded={expanded === 'informationPanel'} onChange={handlePanelChange('informationPanel')}>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>
                                    Important Information
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {consentFormData?.isPersonal ? (
                                    <Typography mt={2}>
                                        <PixSpan>pix</PixSpan>evety seeks your permission to collect and use face biometric data of this Member to:
                                        <ol>
                                            <li>Automatically manage their photos held within {galleryName}, and</li>
                                            <li>Automatically apply photo consent (i.e., for how photos con be used and published by {galleryName})  to photos where that member's face is recognised and tagged (i.e., staff, student, child).</li>
                                        </ol>

                                        The <Link href={configuration.biometric_policy_url} underline='always' target="_blank" rel="noopener">Biometric Consent Privacy Notification Form</Link> explains why <PixSpan>pix</PixSpan>evety collects this data and how automated face recognition technology (AFRT)
                                        is applied. Please read this form before providing your consent.
                                    </Typography>
                                ) : (
                                    <Typography mt={2}>
                                        {galleryName} seeks your permission to collect and use face biometric data of this Member to:
                                        <ol>
                                            <li>Automatically manage their photos held within {galleryName} Gallery, and</li>
                                            <li>Automatically apply their photo consent  (i.e., for how photos can be used and published by {galleryName}) to photos where that Member's face is recognised and tagged (i.e., staff, student, child).</li>
                                        </ol>
                                        The <Link href={configuration.biometric_privacy_url} underline='always' target="_blank" rel="noopener">Biometric Consent Privacy Notification Form</Link> explains why {galleryName} collects this data and how automated face recognition technology (AFRT)
                                        is applied in its Gallery. Please read this form before providing your consent.
                                    </Typography>
                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Formik
                            validationSchema={biometricConsentValidationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                submitBiometricConsent(values);
                            }}
                            enableReinitialize={true}
                            initialValues={requestData}
                        >
                            {({
                                values,
                                //errors,
                                //touched,
                                //handleBlur,
                                handleSubmit,
                                //isSubmitting,
                                isValid
                            }) => (

                                <form className={styles.formContainer} onSubmit={handleSubmit}>
                                    <Accordion expanded={expanded === 'consentPanel'} onChange={handlePanelChange('consentPanel')} sx={{ marginTop: 1 }}>
                                        <AccordionSummary
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                Biometric Consent for {personName}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.avatarContainer}>
                                                <Avatar
                                                    className={styles.avatar}
                                                    src={consentFormData?.memberImageBase64 ? `data:${consentFormData.contentType};base64,${consentFormData.memberImageBase64}` : defaultImage}
                                                />
                                                <Typography className={styles.flexCenter}>{personName}</Typography>
                                            </div>
                                            <Typography mt={2}>I am the person listed above, or I am providing consent on behalf of the person listed above (as their primary legal guardian).</Typography>
                                            <FormControlLabel
                                                label={<div>(Optional) I have discussed this form with the Person listed above and they have agreed to these permissions. </div>}
                                                control={
                                                    <Checkbox
                                                        value={requestData.isPermissionsAgreed}
                                                        onChange={handlePersonAgreeChange}
                                                    />
                                                }
                                                style={{ marginBottom: 10 }}
                                                labelPlacement='end'
                                            />
                                            <h4>Consent to the collection of face biometric data by {galleryName}</h4>
                                            <p>Please select an option below.</p>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="biometric-consent-radio-group"
                                                    defaultValue="false"
                                                    name="radio-buttons-group"
                                                    value={requestData.consentAccepted}
                                                    onChange={handleConsentRadioChange}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label={<div>I consent to the collection of face biometric data of {personName} to automatically identify their face in 
                                                            photos stored in the Gallery for the primary purpose of managing their photos and applying their photo consent in real-time.
                                                            I understand I can change or withdraw this consent at any time in the Gallery or in writing.</div>}
                                                        sx={{ marginBottom: 2 }}
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label={<div>I do not consent to the collection of face biometric data of {personName} in this Gallery. 
                                                            I understand that in not providing this consent, this Member's photo consent will not be automatically applied in 
                                                            the Gallery and can only be manually applied when their face has been identified and their name is tagged to a photo. </div>}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <div className={styles.divider} >
                                                <Divider />
                                            </div>

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className={styles.tableColumn}>Full name of consent giver:  </td>
                                                        <td><strong>{consentFormData?.preApprovedName}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Relationship to person:</td>
                                                        <td>
                                                            <Box className={styles.selectListContainer}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>Select (Optional)</InputLabel>
                                                                    <Select
                                                                        label="Select (Optional)"
                                                                        value={requestData.providerRelationship ?? ''}
                                                                        onChange={handleSelectChange}
                                                                    >
                                                                        <MenuItem value={RelationshipTypes.parent}>{RelationshipTypes.parent}</MenuItem>
                                                                        <MenuItem value={RelationshipTypes.legalGuardian}>{RelationshipTypes.legalGuardian}</MenuItem>
                                                                        {/* <MenuItem value={RelationshipTypes.self}>{RelationshipTypes.self}</MenuItem> */}
                                                                        <MenuItem value={RelationshipTypes.other}>{RelationshipTypes.other}</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date:</td>
                                                        <td>{values.dateAccepted.toDateString()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Footer
                                        onPrev={onClickPrevious}
                                        nextText={(expanded === 'informationPanel' || expanded === false) ? 'Review Biometric Consent' : pageInfo.isFinalPage ? 'Finish' : 'Next'}
                                        showPrev={pageInfo.prevPageRoute !== null && pageInfo.prevPageRoute !== undefined}
                                        disabled={!isValid}
                                        type="submit"
                                    />
                                </form>
                            )}
                        </Formik>
                    </>
                )}
                <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
            </Container>
        );
    }
}

export default BiometricConsent;