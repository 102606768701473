import { Routes as MyRoutes, Route } from 'react-router-dom';
// import { OidcSecure } from '@axa-fr/react-oidc-context';
import Home from '../components/home/home';
import PageNotFound from '../components/error/pageNotFound';
import RegistrationInvite from '../components/registration/registrationInvite';
import Login from '../components/login/login';
import Error from '../components/error/error';
import AuthenticatedRoute from './authenticatedRoute';
import FormComplete from '../components/formComplete/formComplete';
import PassportUpload from '../components/passportUpload/passportUpload';
import Registration from '../components/registration/registration';
import RegistrationComplete from '../components/registration/registrationComplete';
import MediaConsent from '../components/mediaConsent/mediaConsent';
import BiometricConsent from '../components/biometricConsent/biometricConsent';


// const PageNotFound = () => <div>Page not found</div>;

const Routes = () => (
    <MyRoutes>

        {/* Anonymous Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={
            <Login />
        } />
        {/* <Route path="/forms" element={
            <Form />
        } />

        <Route path="/forms/invite" element={
            <Invite />
        } /> */}

        <Route path="/forms/registration" element={
            <Registration />
        } />
        <Route path="/forms/complete" element={
            <FormComplete />
        } />
        <Route path="/error" element={<Error />} />
        {/* only match this when no other routes match */}
        <Route path="*" element={<PageNotFound />} />


        {/* Protected Routes */}
        <Route path="/forms/invite/registration" element={
            <AuthenticatedRoute>
                <RegistrationInvite />
            </AuthenticatedRoute>
        } />
        <Route path="/forms/registration/complete" element={
            <AuthenticatedRoute>
                <RegistrationComplete />
            </AuthenticatedRoute>
        } />
        <Route path="/forms/invite/consent" element={
            <AuthenticatedRoute>
                <MediaConsent />
            </AuthenticatedRoute>
        } />
        <Route path="/forms/invite/passportUpload" element={
            <AuthenticatedRoute>
                <PassportUpload />
            </AuthenticatedRoute>
        } />
        <Route path="/forms/invite/biometricconsent" element={
            <AuthenticatedRoute>
                <BiometricConsent />
            </AuthenticatedRoute>
        } />

    </MyRoutes>
);

export default Routes;