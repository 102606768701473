/*
/ Regular Expressions
/
*/
export const VALID_NAME_REGEX = new RegExp("^(?!\\s*$)[\\p{L}\\p{N}'\\(\\)\\.& -]+$", 'u'); 

//Below regex for email validation was added as per requirements of PXC-24/PXM-41
// Regex is the same used in Pixevety Lib regex validation for email
export const VALID_EMAIL = /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
//export const VALID_EMAIL = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
export const VALID_PHONE = /^[\+]{0,1}[0-9]{8,20}$/;
export const VALID_MOBILE_PHONE = /^(?:\+?61|0)4\)?(?:[ -]?[0-9]){7}[0-9]$/;
export const VALID_PERSON_NAME = /^(?!.*(&|<|>|\")).*$/;
export const INVALID_TAG_CONTENT = /[^'a-zA-Z0-9 -]/;
export const DEFAULT_NAME_PATTERN = "\\p{L}\\p{N}'\\(\\)\\.& -";
export const DEFAULT_TAG_PATTERN = "a-zA-Z0-9";
export const VALID_PASSWORD = /^(?=.*[A-Z])(?=.*\d).{8,}$/;