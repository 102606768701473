import { Navigate } from "react-router-dom";
import { getStoredTokens } from "../services/local-storage";
import { hasTokens } from "../Utils/jwtUtils";

const AuthenticatedRoute = ({ children }: any) => {
  //const { isAuthenticated } = useContext<Auth>(AuthContext);
  const tokens = getStoredTokens();

  if ( hasTokens(tokens) ) {
    return children;
  }

  return <Navigate to="/"></Navigate>;
};
export default AuthenticatedRoute;