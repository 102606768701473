import { useContext } from 'react';
import styles from './header.module.css';
// import logo from '../../media/pixevety-logo-t-50h.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Logout } from '@mui/icons-material';
import AuthContext, { Auth } from '../../contexts/authContext';
import FormContext, { FormState } from '../../contexts/formContext';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const Header = () => {
    const { logout, isAuthenticated } = useContext<Auth>(AuthContext);
    const { formInfo: { formName, personName, systemOnly } } = useContext<FormState>(FormContext);
    const theme = useTheme();

    return (
        <AppBar position="static" color="secondary">

            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ display: { xs: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' } }}>

                    <Box sx={{ flex: '1', display: { xs: 'flex', justifyContent: 'start' } }}>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 0.5,
                                display: { xs: 'none', sm: 'flex' },
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img className={styles.headerLogo} src='https://cdn-testing.pixevety.com/images/pixevety-logo-teal-white-80h.png' alt="Logo" />
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 0.5,
                                display: { xs: 'flex', sm: 'none' },
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img className={styles.headerLogo} src='https://cdn.pixevety.com/images/pixevety-logo-id-80h.png' alt="Logo" />
                        </Typography>
                    </Box>
                    {!systemOnly ? (
                        // <Box sx={{ flex: '1', display: { xs: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }}>
                        <Box sx={{ position: 'absolute', textAlign: 'center' }}>
                            <Typography variant="h5"
                                sx={{
                                    display: { xs: 'none', sm: 'flex' }
                                }}>
                                {formName}
                            </Typography>
                            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                                {personName}
                            </Typography>
                        </Box>
                    ) : <></>}
                    <Box sx={{ flex: '1', display: { xs: 'flex', justifyContent: 'end' } }}>
                        {isAuthenticated ? // Hidden using background colour so still functional for testing
                            <IconButton onClick={() => logout()} sx={{ p: 0, cursor: 'default' }} >
                                <Logout color="secondary" fontSize='large' />
                            </IconButton>
                            :
                            <FontAwesomeIcon icon={light("arrow-progress")} color={theme.palette.primary.main} size="2x" />
                        }
                    </Box>
                </Toolbar>
            </Container>

        </AppBar>
    );
};

export default Header;