export interface FormPageResponse<T> {
    formData: T;
    nextPageRoute?: string | null;
    prevPageRoute?: string | null;
}

/**
 * Represents the response object for the page.
 */
const FINAL_PAGE_ROUTE = '/forms/complete'

export class PageInfo  {
    /**
     * The route of the next page (if any).
     */
    nextPageRoute: string;

    /**
     * The route of the previous page (if any).
     */
    prevPageRoute?: string | undefined | null;

    /**
     * True if this is the final page of a form, i.e., there is no nextPageRoute.
     */
    isFinalPage: boolean;

    /**
     * Message to display if the component cannot be displayed and can be skipped.
     */
    message?: string;

    /**
     * Constructor for the PageInfo class
     * @param nextPageRoute When nextPageRoute is null or undefined, a default next page route is set to the FINAL_PAGE_ROUTE
     * @param prevPageRoute 
     * @param message 
     */
    constructor(nextPageRoute?: string | undefined | null, prevPageRoute?: string | undefined | null, message?: string) {
        
        if (nextPageRoute) {
            this.nextPageRoute = nextPageRoute;
            this.isFinalPage = false;
        } else {
            this.nextPageRoute = FINAL_PAGE_ROUTE;
            this.isFinalPage = true;
        }
        this.prevPageRoute = prevPageRoute;
        this.message = message;
    }
}