import React, { useContext } from 'react';
import { getComponent, getRegistrationDataAsync, validateRegistrationRequest } from '../../services/forms-api-service';
import { useEffect, useState } from 'react';
import { RegistrationRequest, RegistrationFormData } from '../../models/registration';
import SnackMessage, { SnackMessageContent } from '../shared/snackMessage';
import { PageInfo } from '../../models/formPageResponse';
import { useNavigate } from 'react-router-dom';
import { ErrorDetail } from '../error/error';
import { ApiResponse } from '../../models/apiResponse';
import { LoadingState } from '../../models/loadingState';
import { ApiErrorCode } from '../../enums/ApiErrorCode';
import { ErrorPageTitle } from '../constants/errorMessages';
import { RegistrationView } from './registrationView';
import FormContext, { FormState } from '../../contexts/formContext';
import { Component } from '../../enums/components';
import { Routes } from '../../enums/routes';


const Registration = () => {

    const navigate = useNavigate();

    const { formInfo: { formId }, setRegistrationInfo, setFormInfo } = useContext<FormState>(FormContext);
    //const { setUserEmail } = useContext<Auth>(AuthContext);
    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [isLoading, setLoading] = useState<LoadingState>({ showSpinner: false, showSkeleton: true });
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: 'info' });
    const [ageRequirement, setAgeRequirement] = useState<number>();
    const [registrationData, setRegistrationFormData] = useState<RegistrationFormData>({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        termsAndConditionsAccepted: false,
        ageConfirmed: false
    });

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.Registration
        }));
    }, [])

    useEffect(() => {
        const loadPageData = async (formId: number) => {

            try {
                let appSettingsResponse = await getRegistrationDataAsync(abortSignal);
                if (!appSettingsResponse.success) {
                    handleError(appSettingsResponse);
                }
                setAgeRequirement(appSettingsResponse.data?.ageRequirement)

                //Get the page info for this component
                let componentResponse = await getComponent(formId, Component.Registration, abortSignal)
                
                if (!componentResponse.success) {
                    handleError(componentResponse);
                }
                setPageInfo(new PageInfo(componentResponse.data?.nextPageRoute, componentResponse.data?.prevPageRoute));
                setLoading({ ...isLoading, showSkeleton: false });
            } catch (error) {
                console.log(error);
                navigate('/error');
            }
        }


        if (formId) {
           loadPageData(formId);
        }

        return () => {
            abortController.abort();
        };
    }, [formId]);

    
    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.InvalidEmailAddress && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'warning' });
            setSnackOpen(true);
            registrationData.email = "";
            registrationData.confirmEmail = "";

        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled || response.errorCode === ApiErrorCode.FormNotFound) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
        } else {
            navigate('/error');
        }
    }

    const submitRegistration = async (formData: RegistrationFormData, recaptchaToken: string | null | undefined) => {
        try {
            setLoading({ ...isLoading, showSpinner: true });

            if (!recaptchaToken) {
                setSnackMessage({ message: "Please verify the reCAPTCHA", messageLevel: 'error' });
                setSnackOpen(true);
                return;
            }

            //Verify the recaptcha token and the provided email address does not already exist in pixevety.;
            var validateRequestResponse = await validateRegistrationRequest(recaptchaToken, formData.email);

            if (!validateRequestResponse.success) {
                //Need to reset the recaptch token on fail.
                //captchaRef.current.reset(); -- will need to use a forward ref for the recaptch ???
                return handleError(validateRequestResponse);
            }

            //console.log("Token valid.  Proceeding with registration...");
            
            
            const registrationRequest: RegistrationRequest = {
                email: formData.email.trim(),
                firstName: formData.firstName.trim(),
                lastName: formData.lastName.trim(),
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                termsAndConditionsAccepted: formData.termsAndConditionsAccepted,
                ageConfirmed: formData.ageConfirmed ? ageRequirement ?? 999 : 0
            };

            //before submitting registration, we need to complete the TOTP
            setRegistrationInfo({
                registrationRequest: registrationRequest,
                totpEmailAddress: registrationRequest.email,
                complete: false
            });

            // Handle validating OTP
            navigate(pageInfo.nextPageRoute!);

        } catch (error: any) {
            console.error("Failed to submit registration", error);
            navigate('/error')

        } finally {
            setLoading({ ...isLoading, showSpinner: false });
        }
    }

    return (
        <>
            <RegistrationView
                showSpinner={isLoading.showSpinner}
                showSkeleton={isLoading.showSkeleton}
                registrationData={registrationData}
                isAccountHolder={false}
                termsAccepted={false}
                pageInfo={pageInfo}
                isInvite={false}
                ageRequirement={ageRequirement}
                submitRegistration={submitRegistration}
            />
           
            <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
        </>
    )
}

export default Registration