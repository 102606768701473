import { jwtDecode } from "jwt-decode";
import { AuthTokens, AuthUser, AuthUserJwtPayload } from "../models/auth";

export const decodeAccessToken = (token: string): AuthUser => {
    const jwtPayload = jwtDecode<AuthUserJwtPayload>(token);
    const authUser: AuthUser = {
        email: jwtPayload.sub,
        inviteUid: jwtPayload.invite
    };
    return authUser;
}

export const hasTokens = (authTokens?: AuthTokens | null | undefined): boolean => {
    if (authTokens) {
        return hasToken(authTokens.accessToken);
    } else {
        return false;
    }
    
}

export const hasToken = (token: string): boolean => {
    // check token exists
    if (!token) {
        console.error('Invalid or missing auth token!!');
        return false;
    }
    return true;
}