import styled from "styled-components";

export const FormContainer = styled.div`
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around;
`;

export const InputContainer = styled.div`
  width: 45%;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: circle;
  padding-left: 60px;
  width: 45%;
  margin: 0px 0px 30px 30px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px 0px 30px 15px;
    }
`


export const InfoContainer = styled.div`
max-width: 1200px
`
