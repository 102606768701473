import React, { useContext } from 'react';
import { getInviteRegistrationDataAsync, submitUserRegistrationAsync } from '../../services/forms-api-service';
import { useEffect, useState } from 'react';
import { RegistrationInfoResponse, RegistrationFormData, RegistrationInviteRequest } from '../../models/registration';
import SnackMessage, { SnackMessageContent } from '../shared/snackMessage';
import AuthContext, { Auth } from '../../contexts/authContext';
import { FormPageResponse, PageInfo } from '../../models/formPageResponse';
import { useNavigate } from 'react-router-dom';
import { ErrorDetail } from '../error/error';
import { ApiResponse } from '../../models/apiResponse';
import { LoadingState } from '../../models/loadingState';
import { ApiErrorCode } from '../../enums/ApiErrorCode';
import { ErrorPageTitle } from '../constants/errorMessages';
import { RegistrationView } from './registrationView';
import FormContext, { FormState } from '../../contexts/formContext';
import { Routes } from '../../enums/routes';


const RegistrationInvite = () => {

    const navigate = useNavigate();
    const { user } = useContext<Auth>(AuthContext);
    const { setFormInfo } = useContext<FormState>(FormContext);
    
    // const [pageInfo, setPageInfo] = useState<PageInfo>({ prevPageRoute: null, nextPageRoute: "/complete" });
    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [isPixUser, setIsPixUser] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<LoadingState>({ showSpinner: false, showSkeleton: true });
    const [ageRequirement, setAgeRequirement] = useState<number>();
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: 'info' });
    const [registrationData, setRegistrationData] = useState<RegistrationFormData>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        termsAndConditionsAccepted: false,
        ageConfirmed: false
    });

    const abortController = new AbortController();
    const abortSignal = abortController.signal;


    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.RegistrationInvite
        }));
    }, [])

    useEffect(() => {
        //Must have an inviteUid to load the registration data.
        if (user?.inviteUid && user?.inviteUid.length > 0) {
            getRegistrationData();
        }
        
        return () => {
            abortController.abort();
        };
    }, [user?.inviteUid]);

    const handleError = (response: ApiResponse) => {
        
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled || response.errorCode === ApiErrorCode.FormNotFound) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
        } else {
            navigate('/error');
        }
    }

    const getRegistrationData = async () => {
        try {
            const response: ApiResponse<FormPageResponse<RegistrationInfoResponse>> = await getInviteRegistrationDataAsync(user!.inviteUid!, abortSignal);
            if (!response.success) {
                return handleError(response);
            }

            const data = response.data!;
            setRegistrationData({
                ...registrationData,
                firstName: data.formData.firstName,
                lastName: data.formData.lastName,
                email: data.formData.email,
                termsAndConditionsAccepted: data.formData.termsAndConditionsAccepted
            });
            setIsPixUser(data.formData.isPixUser);
            // We need to set terms accepted as seperate from the registration data.
            // This is because whe hide the terms acceptance based on if the user has previously accepted terms.
            // If we based it on the registration data, it would be hidden when they check the box.
            setTermsAccepted(data.formData.termsAndConditionsAccepted); 
            setAgeRequirement(data.formData.ageRequirement);

            setPageInfo(new PageInfo(data.nextPageRoute, data.prevPageRoute));
            setLoading({ ...isLoading, showSkeleton: false });
        } catch (error: any) {
            navigate('/error');
        }
    }

    const submitRegistration = async (formData: RegistrationFormData) => {
        try {
            setLoading({ ...isLoading, showSpinner: true });
            const registrationRequest: RegistrationInviteRequest = {
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                termsAndConditionsAccepted: formData.termsAndConditionsAccepted,
                ageConfirmed: formData.ageConfirmed === true ? ageRequirement ?? 999 : 0, //If age requirment has failed to load, set to 999 so we know there is a problem
                inviteUid: user!.inviteUid!
            };
            const response = await submitUserRegistrationAsync(registrationRequest);
            if (!response.success) {
                return handleError(response);
            }
            setSnackMessage({ message: 'Registration Successful', messageLevel: 'success' })
            setSnackOpen(true);
            navigate(pageInfo.nextPageRoute!);

        } catch (error: any) {
            console.error("Failed to submit registration", error);
            navigate('/error')

        } finally {
            setLoading({ ...isLoading, showSpinner: false });
        }
    }

    return (
        <>
            <RegistrationView
                showSpinner={isLoading.showSpinner}
                showSkeleton={isLoading.showSkeleton}
                registrationData={registrationData}
                isAccountHolder={isPixUser}
                termsAccepted={termsAccepted}
                pageInfo={pageInfo}
                isInvite={true}
                ageRequirement={ageRequirement}
                submitRegistration={submitRegistration}
            />
             <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
        </>
    )
}

export default RegistrationInvite