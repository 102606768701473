/**
 * commonly used error messages.
 */
export const ErrorMessage = {
    ConsentProviderError: "You are not authorised to provide consent for this person.",
    GalleryConsentError: "This Gallery does not have consent enabled.",
    FormDisabledMessage: "This form is no longer available"
}


/**
 * Commonly used Error message titles
 */
export const ErrorPageTitle = {
    PageNotAvailable: "Page is not available",
    SomethingWentWrong: "Something went wrong."
}