import React from "react";

import { Skeleton } from "@mui/material";
import { Container } from "../../shared/styledComponents";


const RegistrationPlaceholder = (props: any) => {

    const buildPaceholderContent = (lines: number) => {
        let content = [];
        for (let i = 0; i < lines; i++) {
            content.push(
                <div key={i}>
                    <Skeleton variant='rectangular' style={{ marginBottom: 10, width: '45%', minWidth: 100 }} />
                    <Skeleton variant='rectangular' height={50} style={{ marginBottom: 30, width: '45%', minWidth: 250 }} />
                </div>
            )
        }
        return content;
    }

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Skeleton variant='rectangular' width={100} style={{ marginBottom: 10 }} />
                <Skeleton variant='rectangular' width={300} height={50} style={{ marginBottom: 30 }} />
            </Container>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap-reverse', maxWidth: 600 }}>
                {buildPaceholderContent(props.lines)}
            </div>
        </>

    )

}

export default RegistrationPlaceholder;