
/**
 * Enaum for the ComponentIds for components.  These enums are 1:1 with the componentIds in the DB
 */
export enum Component {
    RegistrationInvite = 1,
    Consent = 2,
    PassportUpload = 3,
    Registration = 4,
    TOTP = 5,
    CompleteRegistration = 6
}