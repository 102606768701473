import { Skeleton } from "@mui/material";
import styled from "styled-components";

export const ImageContainerRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 400px;
    height: 400px;
    margin: 10px;
    background-color: #ccc;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }  
`;

export const StyledSkeleton = styled(Skeleton)`
    width: 400px;
    height: 400px !important;
    margin: 10px;
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }  
`;

export const ImageContainerContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledImage = styled.img`
    width: 400px;
    height: 400px;
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }  
    object-fit: cover;
`;

export const ButtonContainer = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
`;

export const DeleteIconContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    cursor: pointer;
    z-index: 2;
`;

export const ImageIconContainer = styled.div`
    position: absolute;
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: pointer;
    z-index: 2;
`;