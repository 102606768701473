import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import React from "react";

interface SnackMessageProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    message: string | null | undefined;
    severity: AlertColor;
}

/**
 * Represents the content of a snack message instance.
 */
export interface SnackMessageContent {

    /**
     * The text message to display in the snack message.
     */
    message: string | null | undefined;

    /**
     * The level of the snack message.
     */
    messageLevel: AlertColor;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackMessage = (props: SnackMessageProps) => {

    // Handle if message is null or empty based on message severity
    if (props.message === null || props.message === undefined) {
        switch (props.severity) {
            case "error":
                props.message = "Error";
                break;
            case "info":
                props.message = "Info";
                break;
            case "success":
                props.message = "Success";
                break;
            case "warning":
                props.message = "Warning";
                break;
        }
    }


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setOpen(false);
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={props.open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}


export default SnackMessage;