import { BrowserRouter } from 'react-router-dom';
import Header from './components/header/header';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { AuthContextProvider } from './contexts/authContext';
import { FormContextProvider } from './contexts/formContext';

function App() {

    //
    // primary color  -  #029fa0  or  rgb(2,159,160)
    // #d67129
    // #e08341
    //
    const pixTheme = createTheme({
        palette: {
            primary: {
                main: 'rgb(2,159,160)'
            },
            secondary: {
                main: '#373f49'
            },

        },
    });


    return (
        <div>
            <ThemeProvider theme={pixTheme}>
                <BrowserRouter>
                    <AuthContextProvider>
                        <FormContextProvider>
                            <Header />
                            <Routes />
                        </FormContextProvider>
                    </AuthContextProvider>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
