import { useContext, useEffect, useState } from "react";
import { PageInfo } from "../../models/formPageResponse";
import FormContext, { FormState } from "../../contexts/formContext";
import { getComponent, submitRegistrationAsync } from "../../services/forms-api-service";
import { Loading } from "../shared/loadingWheelOverlay";
import { Component } from "../../enums/components";
import SnackMessage, { SnackMessageContent } from "../shared/snackMessage";
import { ApiResponse } from "../../models/apiResponse";
import { ApiErrorCode } from "../../enums/ApiErrorCode";
import { useNavigate } from "react-router-dom";
import { ErrorPageTitle } from "../constants/errorMessages";
import { ErrorDetail } from "../error/error";
import { RegistrationInfo } from "../../models/registration";



/**
 * 
 * @returns Component form completing a registration after TOTP verification.
 */
const RegistrationComplete = () => {
    const navigate = useNavigate();

    const { formInfo: { formId }, registrationInfo, setRegistrationInfo } = useContext<FormState>(FormContext);

    //Can these all go into a custom hook, i.e., useLoading, useSnack ??
    const [isLoading, setLoading] = useState<boolean>(true);
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: 'info' });



    // Get PageInfo
    //const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    useEffect(() => {
        if (formId) {
            //Get the page info for this component
            getComponent(formId, Component.CompleteRegistration, abortSignal)
                .then(response => {
                    let pageInfo = new PageInfo(response.data?.nextPageRoute, response.data?.prevPageRoute)
                    //setPageInfo(new PageInfo(response.data?.nextPageRoute, response.data?.prevPageRoute));
                    if (registrationInfo?.registrationRequest) {
                        //Check if the registration has already been submitted
                        if (registrationInfo.complete) {
                            navigate(pageInfo.nextPageRoute);
                        } else {
                            //Submit the registration request
                            submitRegistrationAsync(registrationInfo?.registrationRequest).then((registrationResponse: ApiResponse) => {
                                if (!registrationResponse.success) {
                                    return handleError(response);
                                } else {
                                    setRegistrationInfo((prev: RegistrationInfo) => ({
                                        ...prev,
                                        complete: true
                                    }));

                                    navigate(pageInfo.nextPageRoute);
                                }
                            }).catch(error => {
                                console.log(error);
                                navigate('/error');
                            });
                        }
                        
                    } else {
                        console.log("No registration Info");
                        navigate('/error');
                    }
                }).catch(error => {
                    console.log(error);
                })
        }

    
        return () => {
            abortController.abort();
        };
    }, [formId]);


    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
        } else {
            navigate('/error');
        }
    }

    // Submit previously recorded registration data.
    return (
        <>
        <Loading isLoading={ isLoading } />
        <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
        </>
    )
}


export default RegistrationComplete;