import { useContext, useEffect, useState } from 'react';
import styles from './login.module.css'
import { Container, VerifyCodeContainer, VerifyCodeInfoContainer } from './style';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import OtpInput from 'react-otp-input';
import AuthContext, { Auth } from '../../contexts/authContext';
import SnackMessage from '../shared/snackMessage';
import { useNavigate } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
import { Loading } from '../shared/loadingWheelOverlay';
import { PixSpan } from '../shared/styledComponents';
import { ErrorDetail } from '../error/error';
import { ApiErrorCode } from '../../enums/ApiErrorCode';
import { ErrorPageTitle } from '../constants/errorMessages';
import { PageInfo } from '../../models/formPageResponse';
import { getComponent, getInvite, getInviteComponent } from '../../services/forms-api-service';
import { Component } from '../../enums/components';
import { SimpleComponentDetails } from '../../models/form';
import { ApiResponse } from '../../models/apiResponse';
import FormContext, { FormState } from '../../contexts/formContext';
import { Routes } from '../../enums/routes';

const Login = () => {
    const navigate = useNavigate();

    const { formInfo, setFormInfo, registrationInfo } = useContext<FormState>(FormContext)
    const { sendTOTP, loginTOTP } = useContext<Auth>(AuthContext);

    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [pageInfoLoaded, setPageInfoLoaded] = useState<boolean>(false);
    const [invite, setInvite] = useState<any>({ uid: formInfo.inviteUid, emailMasked: null, sent: false }); // TODO: Remove uid when formInfo.inviteUid is functional
    const [totp, setTotp] = useState<string>('');
    const [totpButtonTimerSeconds, setTotpButtonTimerSeconds] = useState<number>(0);
    const [isTotpButtonDisabled, setIsTotpButtonDisabled] = useState<boolean>(true);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<any>({ message: "", severity: "" });

    const totpResendWaitSeconds = 30;
    //let timeoutHandle = useRef<NodeJS.Timeout>();
    let timeoutHandle: any;

    const abortController = new AbortController();
    const abortSignal = abortController.signal;


    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.TOTP
        }));
    }, [])

    //Get the initial page info
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.TOTP
        }));

        if (formInfo.formId) {
            getComponent(formInfo.formId, Component.TOTP, abortSignal)
                .then((response: ApiResponse<SimpleComponentDetails>) => {
                    if (response.success) {
                        setPageInfo(new PageInfo(response.data?.nextPageRoute, response.data?.prevPageRoute));
                        if (response.data?.prevPageRoute === Routes.Registration && !registrationInfo.totpEmailAddress) {
                            //If the previous component was the registration component and we dont have registration info, we have to go back
                            navigate(response.data.prevPageRoute);
                        }
                        //Setting PageInfoLoaded will triggere sending totp.
                        setPageInfoLoaded(true);
                    } else {
                        handleError(response);
                    }                
                }).catch(error => {
                    console.log(error);
                    navigate('/error');
                });

        } else if (formInfo.inviteUid) {
            // Get the component details using the inviteUid.
            getInviteComponent(formInfo.inviteUid, Component.TOTP, abortSignal)
                .then((response: ApiResponse<SimpleComponentDetails>) => {
                    if (response.success) {
                        setPageInfo(new PageInfo(response.data?.nextPageRoute, response.data?.prevPageRoute));
                        if (response.data?.prevPageRoute === Routes.Registration && !registrationInfo.totpEmailAddress) {
                            //If the previous component was the registration component and we dont have registration info, we have to go back
                            navigate(response.data.prevPageRoute);
                        }
                        //Setting PageInfoLoaded will trigger sending totp.
                        setPageInfoLoaded(true);
                    } else {
                        handleError(response);
                    }
                }).catch(error => {
                    console.log(error);
                    navigate('/error');
                });
        } 

        return () => {
            abortController.abort();
            if (timeoutHandle) {
                // cleanup timeout handle
                clearTimeout(timeoutHandle);
            }
        }
    }, [formInfo.inviteUid, formInfo.formId])

    //Handle sending TOTP once the pageInfo has loaded
    useEffect(() => {
        if (pageInfoLoaded && (formInfo.inviteUid || registrationInfo?.totpEmailAddress)) {
            submitSendTOTP();
        }
        return () => {
            abortController.abort();
            if (timeoutHandle) {
                // cleanup timeout handle
                clearTimeout(timeoutHandle);
            }
        }
    }, [pageInfoLoaded]);


    /**
     * Submit the TOTP login when all 6 numbers have been entered.
     */
    useEffect(() => {
        if (totp.length === 6) {
            submitLoginOTP();
            return () => { abortController.abort(); };
        } else {
            // if (!totp || totp === '' || totp.length === 0) {
            // }
        }
        return () => { abortController.abort() };
    }, [totp]);

    useEffect(() => {
        if (totpButtonTimerSeconds >= totpResendWaitSeconds) {
            // set the resend timer - this disables the resend button until the timer completes
            disableTotpButtonAndStartTimer();
        }
    }, [totpButtonTimerSeconds]);


    const clearTOTP = () => {
        // clear otp
        setTotp('');
    }

    /**
     * Send a totp request to the user.
     */
    const submitSendTOTP = async () => {
        setIsLoading(true);

        // clear otp
        setTotp('');
        //If we have either the inviteUid or the email, we can send the TOTP
        if ((formInfo?.inviteUid && formInfo.inviteUid.length > 0) || (registrationInfo?.totpEmailAddress && registrationInfo.totpEmailAddress.length > 0)) {
            try {

                const sendResult = await sendTOTP(abortSignal, formInfo?.inviteUid, registrationInfo?.totpEmailAddress);

                if (sendResult.success && sendResult.data) {
                    // set the resend timer - this disables the resend button until the timer completes
                    setTotpButtonTimerSeconds(totpResendWaitSeconds);
                    setInvite((prevState: any) => ({
                        ...prevState,
                        emailMasked: sendResult.data!.emailMasked,
                        sent: sendResult.data!.sent
                    }));
                    setIsInitialLoad(false);
                    setIsLoading(false);

                } else if (sendResult.errorCode === ApiErrorCode.NonFatalError && !sendResult.cancelled) {
                    setTotpButtonTimerSeconds(totpResendWaitSeconds);
                    setInvite((prevState: any) => ({
                        ...prevState,
                        sent: false
                    }));
                    setIsInitialLoad(false);
                    setIsLoading(false);

                } else if (sendResult.errorCode === ApiErrorCode.GalleryFormDisabled) {
                    // Gallery Form Disabled
                    navigate('/error', { state: { message: sendResult.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
                } else {
                    // Fatal Error
                    navigate('/error');
                }

            } catch (error) {
                console.log("Failed", error);
                navigate('/error');
            }
        }
    }

    const submitLoginOTP = async () => {
        if ((formInfo?.inviteUid && formInfo.inviteUid.length > 0) || (registrationInfo?.totpEmailAddress && registrationInfo?.totpEmailAddress?.length > 0)) {
            setIsLoading(true);
            try {
                const result = await loginTOTP(totp, abortSignal, formInfo.inviteUid, registrationInfo?.totpEmailAddress);
                if (result.success) {
                    if (formInfo.inviteUid && formInfo.inviteUid.length > 0) {
                        var inviteDetails = await getInvite(formInfo.inviteUid!, abortSignal)
                        if (inviteDetails.success) {
                            setFormInfo(prevFormInfo => ({
                                ...prevFormInfo,
                                formName: inviteDetails.data?.formName,
                                systemOnly: inviteDetails.data?.systemOnly,
                                personName: inviteDetails.data?.personName,
                                galleryName: inviteDetails.data?.galleryName
                            }));
                        } else if (inviteDetails.errorCode === ApiErrorCode.GalleryFormDisabled) {
                            navigate('/error', { state: { message: inviteDetails.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail })
                        } else {
                            navigate('/error');
                        }
                    }
                    setIsLoading(false);
                    navigate(pageInfo.nextPageRoute);
                    //return true;
                } else if (result.errorCode === ApiErrorCode.NonFatalError) {
                    setSnackMessage({ message: "Invalid security OTP! Try again", severity: "error" });
                    setSnackOpen(true);
                } else if (result.errorCode === ApiErrorCode.GalleryFormDisabled) {
                    navigate('/error', { state: { message: result.errorMessage, title: ErrorPageTitle.PageNotAvailable }})
                } else {
                    navigate('/error');
                }
            } catch (error: any) {
                navigate('/error');
            } finally {
                setIsLoading(false);
            }
        } else {
            // if there is no invite, then this should be redirected back to home!
            navigate("/");
        }
        return false;
    }

    const disableTotpButtonAndStartTimer = () => {
        setIsTotpButtonDisabled(true);

        const intervalHandle = setInterval(() => {
            setTotpButtonTimerSeconds(totpButtonTimerSeconds => totpButtonTimerSeconds - 1);
        }, 1000);

        timeoutHandle = setTimeout(() => {
            // cleanup interval handle
            clearInterval(intervalHandle);
            // zero out timer and enable the resend TOTP button
            setTotpButtonTimerSeconds(0);
            setIsTotpButtonDisabled(false);

        }, totpButtonTimerSeconds * 1000);
    }

    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail })
        } else {
            navigate('/error');
        }
    }

    return (
        <Container>
            <Loading isLoading={isLoading} />

            {!isInitialLoad ? (
                <>
                    <VerifyCodeContainer>

                        <Card variant="outlined" sx={{ minWidth: 250 }}>
                            <CardHeader
                                action={
                                    <Lock fontSize='large' color="primary" />
                                }
                                title="Confirm Your Identity"
                            // subheader="A verification code has been emailed to you"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="subtitle1" component="div" textAlign="center" fontWeight='fontWeightMedium' >
                                    {/* Enter verification code */}
                                    Please enter your One-Time Password code below to verify your identity.
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <OtpInput
                                        // containerStyle='container'
                                        inputStyle={styles.inputStyle}
                                        numInputs={6}
                                        onChange={setTotp}
                                        // renderSeparator={<span>&nbsp;</span>}
                                        value={totp}
                                        inputType='tel'
                                        renderInput={(props) => <input {...props} />}
                                        shouldAutoFocus={true}
                                    />
                                </div>
                            </CardContent>
                            <CardActions style={{ display: "flex", justifyContent: "center" }}>
                                <Button variant="outlined" color='primary' size="small" disabled={isTotpButtonDisabled} onClick={submitSendTOTP}>
                                    Resend code
                                    {(totpButtonTimerSeconds > 0) ? (
                                        <span> ({totpButtonTimerSeconds} secs)</span>
                                    ) : null}
                                </Button>
                                &nbsp;
                                <Button variant="outlined" color='secondary' size="small" disabled={totp.trim() === ''} onClick={clearTOTP}>
                                    Clear
                                </Button>
                            </CardActions>
                        </Card>
                    </VerifyCodeContainer>
                    <VerifyCodeInfoContainer>
                        <Typography gutterBottom variant="body2" component="div" textAlign="center" >
                            To help <PixSpan>pix</PixSpan>evety keep your account and information secure,
                        </Typography>
                        {invite.emailMasked && invite.emailMasked !== null ? (
                            <Typography gutterBottom variant="body2" component="div" textAlign="center" >
                                a One-Time Password code has been sent to {invite.emailMasked}
                            </Typography>
                        ) : (
                            <Typography gutterBottom variant="body2" component="div" textAlign="center" >
                                a One-Time Password code has been sent to your email recently.
                            </Typography>
                        )}

                        <Divider light={true} sx={{ margin: "10px" }} />

                        <Typography gutterBottom variant="caption" component="div" textAlign="center" fontSize={10}>
                            Don't forget to check your spam and junk folders if you do not receive this email in your inbox.
                        </Typography>
                    </VerifyCodeInfoContainer>
                </>
            ) : null}
            <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.severity} />
        </Container>
    )
};

export default Login;