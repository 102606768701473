import { Container, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackMessage, { SnackMessageContent } from "../shared/snackMessage";
import { FormPageResponse, PageInfo } from "../../models/formPageResponse";
import AuthContext, { Auth } from "../../contexts/authContext";
import { useContext, useEffect, useState } from "react";
import { ConsentDetailsResponse, ConsentQuestion, ConsentQuestionResponse, UpdateConsentDetailsRequest } from "../../models/consent";
import { getConsentDetailsAsync, submitConsentDetailsAsync } from "../../services/forms-api-service";
import ConsentQuestionSelector from "./components/consentQuestionSelector";
import styles from './mediaConsent.module.css';
import Footer from "../footer/footer";
import ConsentPlaceholder from "./components/consentPlaceholder";
import { Loading } from "../shared/loadingWheelOverlay";
import { ErrorDetail } from "../error/error";
import { ApiResponse } from "../../models/apiResponse";
import { LoadingState } from "../../models/loadingState";
import { ApiErrorCode } from "../../enums/ApiErrorCode";
import { ErrorPageTitle } from "../constants/errorMessages";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '../shared/Accordian';
import FormContext, { FormState } from "../../contexts/formContext";
import { Routes } from "../../enums/routes";
import configuration from "../../config";

const MediaConsent = () => {

    const navigate = useNavigate();

    const { user } = useContext<Auth>(AuthContext);
    const { formInfo: { galleryName, personName }, setFormInfo } = useContext<FormState>(FormContext)

    const [consentDetails, setConsentDetails] = useState<ConsentDetailsResponse | null>(null);
    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [isLoading, setLoading] = useState<LoadingState>({ showSkeleton: false, showSpinner: true });
    const [skipComponent, setSkipComponent] = useState<boolean>(false);
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: "info" });
    const [expanded, setExpanded] = useState<string | false>('informationPanel');

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.Consent
        }));
    }, [])

    useEffect(() => {
        if (user?.inviteUid && user?.inviteUid.length > 0) {
            getConsentDetails();
        }
        return () => { abortController.abort() };
    }, [user?.inviteUid]);

    //TODO: Create custom hook for handleError
    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail });
        } else {
            navigate('/error');
        }
    }

    const handlePanelChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * Retrieves the consent details for the current user's inviteUid.
     * - If successful, updates the component state with the consent details and page info.
     * - If unsuccessful, logs an error to the console and displays messages.
     */
    const getConsentDetails = async () => {
        try {
            setLoading({ ...isLoading, showSkeleton: true });

            // Request consent details from the API
            const response: ApiResponse<FormPageResponse<ConsentDetailsResponse>> = await getConsentDetailsAsync(user!.inviteUid!, abortSignal);

            if (response.errorCode === ApiErrorCode.FatalError) {
                // If we have a fatal error, navigate to the error page.
                return navigate('/error');
            }

            // Update the component state with the consent details and page info
            const data = response.data!;
            setPageInfo(new PageInfo(data.nextPageRoute, data.prevPageRoute, response.errorMessage))

            if (response.success) {
                setConsentDetails(data.formData);

                // Populate the initial values for the consent details
                const updatedQuestions = data.formData.questions.map((question) => {
                    return {
                        ...question,
                        value: question.value,
                    };
                });

                setConsentDetails((prevState) => {
                    if (prevState === null) {
                        return null;
                    }

                    return {
                        ...prevState,
                        questions: updatedQuestions,
                    };
                });
            } else {
                setSkipComponent(true);
            }
            setLoading({ showSkeleton: false, showSpinner: false });

        } catch (error: any) {
            console.error("ERROR retrieving consent details", error);
            navigate('/error');
        }
    }

    /**
     * Click event handler for the next navigation button.
     *  Submits consent details to the API.
     * - If successful, displays messages and navigates to the next page (if any).
     * - If unsuccessful, logs an error to the console and displays messages.
     */
    const onClickNext = async () => {
        try {

            //if the consent panel is not open, just open it rather than submit
            if (expanded === 'informationPanel' || expanded === false) {
                setExpanded('consentPanel');
                return;
            }

            if (!user?.inviteUid) {
                console.error("InviteUid is required for this form component.");
                navigate('/error');
            }

            setLoading({ ...isLoading, showSkeleton: false, showSpinner: true });
            if (!consentDetails) {
                return;
            }

            // Get the consent values from the component state
            const consentQuestionResponses: ConsentQuestionResponse[] = consentDetails?.questions.map((question) => {
                return {
                    questionId: question.id,
                    value: question.value
                };
            });

            const updateConsentRequest: UpdateConsentDetailsRequest = {
                inviteUid: user!.inviteUid!,
                consentQuestionResponses: consentQuestionResponses
            };

            const response = await submitConsentDetailsAsync(updateConsentRequest, abortSignal);
            if (!response.success) {
                return handleError(response);
            }

            // Show success message and navigate to next page
            setSnackMessage({ message: 'Submitted Consent', messageLevel: 'success' })
            setSnackOpen(true);
            navigate(pageInfo.nextPageRoute!);
        } catch (error: any) {
            navigate('/error');
        } finally {
            setLoading({ ...isLoading, showSkeleton: false, showSpinner: false });
        }
    }

    const handleQuestionChange = (questionId: number, value: number) => {
        setConsentDetails((prevState) => {
            if (prevState === null) {
                return null;
            }

            // Update the questions with the new value for the updated question
            const updatedQuestions = prevState.questions.map((question) => {
                if (question.id === questionId) {
                    return {
                        ...question,
                        value: value,
                    };
                }
                return question;
            });

            return {
                ...prevState,
                questions: updatedQuestions,
            };
        });
    };

    /**
     * Returns an array of 'Access' consent questions.
     */
    const accessQuestions = (): ConsentQuestion[] => {
        if (!consentDetails) {
            return [];
        }
        return consentDetails.questions.filter(x => x.grouping === "Access");
    };

    /**
     * Returns an array of 'Publishing and Usage' consent questions.
     */
    const publishingQuestions = (): ConsentQuestion[] => {
        if (!consentDetails) {
            return [];
        }
        return consentDetails.questions.filter(x => x.grouping === "Publishing and Usage");
    };

    /**
     * Click event handler for the previous navigation button.
     */
    const onClickPrevious = () => {
        if (pageInfo.prevPageRoute) {
            navigate(pageInfo.prevPageRoute);
        }
    };

    if (skipComponent) {
        return (
            <div className={styles.centerContent}>
                <Typography gutterBottom variant="h3" component="div" textAlign="center" color="primary" >
                    Page is not available.
                </Typography>

                <Typography gutterBottom variant="h6" component="div" textAlign="center" fontWeight='fontWeightLight' >
                    {pageInfo.message}
                </Typography>
                <Footer
                    onNext={() => navigate(pageInfo.nextPageRoute!)}
                    onPrev={onClickPrevious}
                    nextText={pageInfo.isFinalPage ? 'Finish' : 'Skip'}
                    showPrev={pageInfo.prevPageRoute !== null && pageInfo.prevPageRoute !== undefined}
                />
            </div>
        )
    } else {
        return (
            <Container>
                <Loading isLoading={isLoading.showSpinner} />
                <h1 className={styles.centerText}>Media Consent</h1>
                {isLoading.showSkeleton ? (
                    <ConsentPlaceholder lines={6} />
                ) : (
                    <>
                        <Accordion expanded={expanded === 'informationPanel'} onChange={handlePanelChange('informationPanel')}>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>
                                    Important Information
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <p>
                                    {galleryName} seeks your consent for the publishing and use of school images (namely photos and videos) featuring your child. <br /><br />
                                    As part of the school's everyday functions, we collect and manage images of students. Our functions in this area include communicating with
                                    parents and the wider community, communicating with staff, and recognising, promoting, and/or rewarding achievements. To fulﬁl these functions,
                                    images may be published via our school portal, email, or school newsletter. Likewise, images may be shared with our member families and the community
                                    more broadly via our school website or dedicated social media feeds, in brochures, or other external marketing publications to promote the school.
                                    On occasion, the media may visit us and take photographs, video or audio of our activities or newsworthy events. <br /><br />
                                    We are committed to ensuring we handle images in a way that is easy to follow and provides choice and control to you. We use a detailed media consent form for this purpose (paper form also available at request) where we ask you to make clear decisions about access to, and use of, images held in our school's pixevety gallery. After submitting this form, you can change your consent at any time by contacting the school.
                                    Please complete this online form and submit it to the school.
                                    Thank you.
                                </p> */}
                                <p style={{margin: 20}}>
                                    {galleryName} seeks your consent for the use and publishing of photos that feature this Member (staff, student, child).
                                    <br/><br/>
                                    As part of its everyday functions, {galleryName} collects and manages photos of Members to communicate with staff, 
                                    parents and the wider community, and to recognise and promote member achievements. To fulfil these functions, photos may be published 
                                    via its portal, email or school newsletter. Likewise, photos may be shared with Member families and the community more broadly 
                                    via its website or dedicated social media feeds, in brochures or other external marketing publications/channels for promotional 
                                    purposes. On occasion, the media may visit and take photos of activities or newsworthy events.
                                    <br/><br/>
                                    {galleryName} is committed to ensuring it handles Member photos in a way that is easy to follow and provides choice and control to 
                                    Members and their legal guardians. This detailed online Media Consent Form is used for that purpose (paper form also available on 
                                    request) allowing you to make clear decisions about access to, and use of, Member photos held in the {galleryName} pixevety Gallery. 
                                    The <Link href={configuration.media_consent_privacy_url} underline='always' target="_blank" rel="noopener">Media Consent Privacy Notification Form</Link> explains why {galleryName} seeks your consent for the use and publishing of Member 
                                    photos. Once this form is submitted, you can change your consent at any time by accessing the Gallery or contacting {galleryName}. 
                                </p>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'consentPanel'} onChange={handlePanelChange('consentPanel')} sx={{ marginTop: 1 }}>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>
                                    Media Consent for {personName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {isLoading.showSkeleton ? (
                                    <ConsentPlaceholder lines={6} />
                                ) : (
                                    consentDetails !== undefined && consentDetails !== null ? (
                                        <>
                                            <h3 className={styles.centerText}>Gallery Access Settings</h3>
                                            {accessQuestions().map((question, index) => (
                                                <ConsentQuestionSelector
                                                    key={index}
                                                    options={consentDetails.options.filter(x => x.grouping === "Access")}
                                                    question={question}
                                                    onChange={(value) => handleQuestionChange(question.id, value)}
                                                />
                                            ))}

                                            <hr />

                                            <h3 className={styles.centerText}>Publishing &amp; Usage Settings</h3>
                                            {publishingQuestions().map((question, index) => (
                                                <ConsentQuestionSelector
                                                    key={index}
                                                    options={consentDetails.options.filter(x => x.grouping === "Publishing and Usage")}
                                                    question={question}
                                                    onChange={(value) => handleQuestionChange(question.id, value)}
                                                />
                                            ))}
                                        </>

                                    ) : (null)

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Footer
                            onNext={onClickNext}
                            onPrev={onClickPrevious}
                            nextText={(expanded === 'informationPanel' || expanded === false) ? 'Review Media Consent' : pageInfo.isFinalPage ? 'Finish' : 'Next'}
                            showPrev={pageInfo.prevPageRoute !== null && pageInfo.prevPageRoute !== undefined}
                        />
                    </>
                )}
                <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
            </Container>
        );
    }
}

export default MediaConsent;