import { FileUploadStatus } from "../../enums/fileUploadStatus";
import { AWSUploadPartResponse, FileUploadPart, InitialiseMultipartFileUploadResponse, MultipartUploadCompleteFileDetails } from "../../models/fileUpload";
import { finaliseMultipartUploadAsync } from "../../services/forms-api-service";

const COMPLETE = 100;

export class ImageUploadItem {
    image: any;
    inviteId: string;
    galleryId: number;
    fileName: string;
    albumId: number;
    private uploadDetails: InitialiseMultipartFileUploadResponse;
    private completeParts: AWSUploadPartResponse[];
    private imagePartPromises: any[];

    validPassportImage?: boolean;
    private partsProgress: number[] = [];
    percentComplete: number = 0;
    status: FileUploadStatus = FileUploadStatus.Uploading;
    errorMessage: string = "";

    constructor(image: any, inviteId: string, galleryId: number, fileName: string, albumId: number, uploadDetails: InitialiseMultipartFileUploadResponse) {
        this.image = image;
        this.inviteId = inviteId;
        this.galleryId = galleryId;
        this.fileName = fileName;
        this.albumId = albumId;
        this.uploadDetails = uploadDetails;
        this.completeParts = [];
        this.imagePartPromises = [];
        this.percentComplete = 0;
    }

    updateImageItem(error?: any) { };

    attachUpdateImageItem(handler: any) {
        this.updateImageItem = handler;
    }

    async uploadFileAsync() {
        try {
            const blob = await fetch(this.image).then(r => r.blob());
            if (this.uploadDetails.multipartUrls) {
                const chunkSize = this.uploadDetails.chunkSize;
                const keys = Object.keys(this.uploadDetails.multipartUrls);
                for (const indexStr of keys) {
                    const index = parseInt(indexStr);
                    const start = index * chunkSize;
                    const end = (index + 1) * chunkSize;
                    const part = index < keys.length - 1
                        ? blob.slice(start, end)
                        : blob.slice(start);

                    const imagePart: FileUploadPart = {
                        url: this.uploadDetails.multipartUrls[index].url,
                        partNumber: this.uploadDetails.multipartUrls[index].partNumber
                    };
                    this.partsProgress.push(0);
                    this.imagePartPromises.push(this.uploadPart(imagePart, part))
                }

                await Promise.all(this.imagePartPromises);

                this.status = FileUploadStatus.Uploaded;
                this.updateImageItem();

                await this.finaliseMultipartUpload();
            }
        } catch (error: any) {
            this.validPassportImage = false;
            this.status = FileUploadStatus.Failed;
            this.errorMessage = "Unable to upload file."
            this.updateImageItem(error)
        }
    }

    private async uploadPart(part: FileUploadPart, stream: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', part.url);
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');

            xhr.onload = async () => {
                const headers = this.parseHeaders(xhr.getAllResponseHeaders());

                resolve(await this.resolveUploadPart(
                    { partNumber: part.partNumber, etag: headers.etag }
                ));
            };

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const partPercentage = Math.round((event.loaded / event.total) * 100)
                    var total = Math.round(this.calculateTotalCompletePercent(this.partsProgress, part.partNumber - 1, partPercentage));
                    if (total > this.percentComplete) {
                        total >= COMPLETE ? this.percentComplete = COMPLETE : this.percentComplete = total;
                        this.updateImageItem();
                    }
                }
            };

            xhr.onerror = () => {
                reject(xhr.statusText);
            };

            xhr.send(stream);
        })
    }

    private async resolveUploadPart(response: any) {
        const completePart: AWSUploadPartResponse = {
            partETag: response.etag!,
            partNumber: response.partNumber
        };

        this.completeParts.push(completePart);
        return response;
    }

    /*
    * Finalises the multipart upload
    */
    private async finaliseMultipartUpload() {
        if (this.uploadDetails) {
            const payload: MultipartUploadCompleteFileDetails = {
                "galleryId": this.galleryId,
                "inviteUid": this.inviteId,
                "uploadId": this.uploadDetails.uploadId,
                "fileName": this.fileName,
                "completeParts": this.completeParts
            };
            try {
                const result = await finaliseMultipartUploadAsync(payload);
                if (result.success) {
                    this.validPassportImage = true;
                } else {
                    this.errorMessage = result.errorMessage ? result.errorMessage : "An error occurred uploading this image.";
                }
                this.status = FileUploadStatus.Finalised;
                this.updateImageItem();
            } catch (error: any) {
                console.error("Error finalising multiplart upload: ", error);
                throw error;
            }
        } else {
            console.error("Error Finalising multipart request.  No Upload Request Response for file: ", this.fileName);
            throw new Error("Invalid Upload Details");
        }
    }

    //Currently not used
    calculatePercentComplete = () => {
        const total = this.imagePartPromises.length;
        this.percentComplete = (this.completeParts.length / total) * 100;
    }

    parseHeaders(headers: string): Record<string, string> {
        const result: Record<string, string> = {};
        headers.trim().split('\r\n').forEach(function (line) {
            const parts = line.split(':');
            const key = parts.shift()!.trim();
            const value = parts.join(':').trim();
            result[key] = value;
        });
        return result;
    }

    calculateTotalCompletePercent = (arr: number[], index: number, newValue: number): number => {
        arr[index] = newValue;
        const total = arr.reduce((sum, num) => sum + num, 0);
        return total / arr.length;
    }
}


