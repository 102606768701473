import { Button, Divider, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from './style';
import { useContext } from 'react';
import AuthContext, { Auth } from '../../contexts/authContext';
import FormContext, { FormState } from '../../contexts/formContext';

export interface ErrorDetail {

    /*
    * The title to display for the error page.
    */
    title: string;

    /*
    * The message to display under the heading on the error page.
    */
    message: string;
}


const Error = () => {
    //const { isAuthenticated } = useContext<Auth>(AuthContext);
    const { formInfo: { currentPageRoute } } = useContext<FormState>(FormContext);

    const navigate = useNavigate();
    const location = useLocation();

    const errorDetail: ErrorDetail = {
        message: location.state?.message,
        title: location.state?.title
    };

    if (!errorDetail.title) {
        errorDetail.title = "Something Went Wrong";
    }

    if (!errorDetail.message || errorDetail.message?.toLowerCase() === "internal server error") {
        errorDetail.message = "We are unable to process your request.";
    }
    console.log("Page route", currentPageRoute);

    const gotoFormPage = async () => {
        console.log("Go To Form error page!");
        if (currentPageRoute) {
            navigate(currentPageRoute);
        } 
        
    }

    return (
        <Container>
            <Typography gutterBottom variant="h3" component="div" textAlign="center" color="primary" >
                {/* Something went wrong */}
                {errorDetail.title}
            </Typography>

            <Typography gutterBottom variant="h6" component="div" textAlign="center" fontWeight='fontWeightLight' >
                {errorDetail.message}
            </Typography>

            {currentPageRoute  ? (
                <>
                    <Divider light={true} sx={{ margin: "10px" }} />

                    <Button variant="outlined" onClick={gotoFormPage}>
                        Return to Form
                    </Button>
                </>
            ) : null}

        </Container>
    );
};

export default Error;