import React, { useState } from 'react';
import { TextField, Typography, FormControlLabel, Checkbox, Link, FormControl, AccordionDetails, Card, CardContent } from '@mui/material';
import { Formik } from 'formik';
import { FormContainer, InfoContainer, InputContainer, StyledList } from './style';
import RegistrationPlaceholder from './components/registrationPlaceholder';
import { RegistrationFormData } from '../../models/registration';
import { Accordion, AccordionSummary } from './components/styledAccordion';
import { PageInfo } from '../../models/formPageResponse';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../shared/loadingWheelOverlay';

import { Container, PixSpan } from '../shared/styledComponents';
import configuration from '../../config';
import { newRegistrationSchema, pixUserRegistrationSchema, registrationSchema } from './registrationSchema';
import Footer from '../footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorContainer } from '../shared/Styles/appStyle';

interface RegistrationViewProps {
    showSpinner: boolean;
    showSkeleton: boolean;
    registrationData: RegistrationFormData;
    isAccountHolder: boolean;
    termsAccepted: boolean;
    pageInfo: PageInfo;
    isInvite: boolean;
    ageRequirement?: number;
    submitRegistration: (formData: RegistrationFormData, recaptchaToken?: string | null | undefined) => void;
}

const MIN_AGE_ALT_DESCRIPTION = 18;  //Hardcoded min age to show alternate description for age confirmation.

export const RegistrationView: React.FC<RegistrationViewProps> = (props) => {

    const navigate = useNavigate();
    /**
     * Click event handler for the previous navigation button
     */
    const onClickPrevious = () => {
        if (props.pageInfo.prevPageRoute) {
            navigate(props.pageInfo.prevPageRoute);
        }
    }

    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();

    const recaptchaRef = React.createRef<ReCAPTCHA>()
    const onSubmit = () => {
        if (recaptchaRef.current) {
            setRecaptchaToken(recaptchaRef.current.getValue());
        }
    }


    return (
        <Container>
            <Loading isLoading={props.showSpinner} />
            <h1 style={{ textAlign: "center" }}>Personal Account Registration</h1>
            {props.showSkeleton ? (
                <RegistrationPlaceholder lines={6} />
            ) : (
                <div>
                    <div style={{ margin: 30 }}>
                        <div style={{ marginBottom: 20 }}>
                            <Typography sx={{ paddingBottom: 5 }}>Register for a FREE personal account to connect to other <PixSpan>pix</PixSpan>evety Galleries</Typography>
                        </div>
                        {props.isInvite ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 24, marginRight: 5 }} color="text.primary" gutterBottom>
                                    Email Address:
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    {props.registrationData.email}
                                </Typography>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <Formik
                        initialValues={props.registrationData}
                        enableReinitialize={true}
                        validationSchema={props.isInvite ? !props.isAccountHolder ? registrationSchema : pixUserRegistrationSchema : newRegistrationSchema}
                        validate={(values) => {
                            const errors: any = {};
                            if (!props.termsAccepted && !values.termsAndConditionsAccepted) {
                                errors.termsAndConditionsAccepted = "You must accept the terms of service"
                            }
                            if (!props.isAccountHolder && !values.ageConfirmed) {
                                errors.ageConfirmed = "You must confirm you age"
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            props.submitRegistration(values, recaptchaToken)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleChange
                        }) => (
                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormContainer>
                                    {!props.isInvite ?
                                        <>
                                            <InputContainer>
                                                <TextField
                                                    type='text'
                                                    name="email"
                                                    label="Email Address"
                                                    placeholder=''
                                                    onChange={handleChange("email")}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    fullWidth={true}
                                                />
                                                {errors.email && touched.email ? (
                                                    <ErrorContainer>{errors.email}</ErrorContainer>
                                                ) : (null)
                                                }
                                            </InputContainer>
                                            <InputContainer>
                                                <TextField
                                                    type='text'
                                                    name="confirmEmail"
                                                    label="Confirm Email Address"
                                                    placeholder=''
                                                    onChange={handleChange("confirmEmail")}
                                                    onBlur={handleBlur}
                                                    value={values.confirmEmail}
                                                    fullWidth={true}
                                                />
                                                {errors.confirmEmail ? (
                                                    <ErrorContainer>{errors.confirmEmail}</ErrorContainer>
                                                ) : (null)
                                                }
                                            </InputContainer>
                                        </>
                                        :
                                        <></>
                                    }
                                    <InputContainer>
                                        <TextField
                                            type='text'
                                            name="firstName"
                                            label="First Name"
                                            placeholder=''
                                            onChange={handleChange("firstName")}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            fullWidth={true}
                                        />
                                        {errors.firstName && touched.firstName ? (
                                            <ErrorContainer>{errors.firstName}</ErrorContainer>
                                        ) : (null)
                                        }
                                    </InputContainer>
                                    <InputContainer>
                                        <TextField
                                            type='text'
                                            name="lastName"
                                            label="Last Name"
                                            placeholder=''
                                            onChange={handleChange("lastName")}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                            fullWidth={true}
                                        />
                                        {errors.lastName && touched.lastName ? (
                                            <ErrorContainer>{errors.lastName}</ErrorContainer>
                                        ) : (null)
                                        }
                                    </InputContainer>
                                    {!props.isAccountHolder ? (
                                        <>
                                            <InputContainer>
                                                <TextField
                                                    type={'password'}
                                                    name="password"
                                                    label="Password"
                                                    onChange={handleChange("password")}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    fullWidth={true}
                                                />
                                                {errors.password && touched.password ? (
                                                    <ErrorContainer>{errors.password}</ErrorContainer>
                                                ) : (null)
                                                }
                                            </InputContainer>
                                            <InputContainer>
                                                <TextField
                                                    type={'password'}
                                                    name="confirmPassword"
                                                    label="Confirm Password"
                                                    onChange={handleChange("confirmPassword")}
                                                    onBlur={handleBlur}
                                                    value={values.confirmPassword}
                                                    fullWidth={true}
                                                />
                                                {errors.confirmPassword && touched.confirmPassword ? (
                                                    <ErrorContainer>{errors.confirmPassword}</ErrorContainer>
                                                ) : (null)
                                                }
                                            </InputContainer>

                                        </>
                                    ) : (null)}

                                </FormContainer>
                                {!props.isAccountHolder ? (
                                    <>
                                        <StyledList>
                                            <li>At least 8 characters</li>
                                            <li>Contain a numeric character</li>
                                            <li>Contain an uppercase and lowercase character</li>
                                        </StyledList>
                                    </>
                                ) : null}
                                <Card style={{ margin: 30 }}>
                                    <CardContent>
                                        <FormControl>
                                            {!props.termsAccepted ?
                                                <div style={{ marginBottom: 20 }}>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Agree to terms</Typography>
                                                    <p>
                                                        To continue using the <PixSpan>pix</PixSpan>evety Platform you must agree to the <Link href={configuration.terms_url} underline='hover' target="_blank" rel="noopener">Terms of Service</Link>.< br/> 
                                                        If you are a parent or legal guardian, you are responsible for your child's use of pixevety products and services. 
                                                    </p>
                                                    <FormControlLabel
                                                        label={<div>I have read and agree to <PixSpan>pix</PixSpan>evety's <Link href={configuration.terms_url} underline='hover' target="_blank" rel="noopener">Terms of Service</Link></div>}
                                                        control={
                                                            <Checkbox
                                                                value={values.termsAndConditionsAccepted}
                                                                onChange={handleChange("termsAndConditionsAccepted")}
                                                            />
                                                        }
                                                        labelPlacement='end'
                                                        style={{marginLeft: 0}}
                                                    />
                                                    {errors.termsAndConditionsAccepted && touched.termsAndConditionsAccepted ?
                                                        <ErrorContainer>You must accept our Terms of Service to use the Pixevety platform.</ErrorContainer>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            {!props.isAccountHolder ? (
                                                <div style={{ marginBottom: 20 }}>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Confirm your age</Typography>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', maxWidth: '80%'}}>
                                                        <Checkbox
                                                            value={values.ageConfirmed}
                                                            onChange={handleChange("ageConfirmed")}
                                                        />
                                                        <p style={{marginTop: 5}}>
                                                            I confirm I am {props.ageRequirement} years of age or older.
                                                            {props.ageRequirement && props.ageRequirement < MIN_AGE_ALT_DESCRIPTION ? 
                                                            <>  If I am between the ages of {props.ageRequirement} and {MIN_AGE_ALT_DESCRIPTION}, I confirm my account will be supervised by a parent 
                                                                or legal guardian who agrees to be bound by these <Link href={configuration.terms_url} underline='hover' target="_blank" rel="noopener">Terms</Link>. 
                                                            </> : <></>}
                                                            <strong><br/> If under the age of {props.ageRequirement} you cannot register to use this service.</strong>
                                                        </p>
                                                    </div>
                                                    {errors.ageConfirmed && touched.ageConfirmed ?
                                                        <ErrorContainer>pixevety is only available to individuals that are {props.ageRequirement} years and older. </ErrorContainer>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            ) : null
                                            }

                                        </FormControl>
                                        <InfoContainer style={{ marginTop: 20 }}>
                                            <Accordion elevation={0} defaultExpanded={true}>
                                                <AccordionSummary>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Collection Notice</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                                        <span>In completing this registration you are providing personal information to </span>
                                                        <span><PixSpan>pix</PixSpan>evety to allow us to provide you with our services and to send </span>
                                                        <span>you system notifications.  We collect and manage personal information during registration in accordance with our </span>
                                                        <span><Link href={configuration.terms_url} underline='hover' target="_blank" rel="noopener">Terms of Service</Link> </span>
                                                        <span>and <Link href={configuration.privacy_url} underline='hover' target="_blank" rel="noopener">Privacy Policy</Link>. </span>
                                                        <span>Depending on your nature of interaction with <PixSpan>pix</PixSpan>evety, we may ask to collect biometric face recognition data to manage photo consent on behalf of our clients.  </span>
                                                        <span>Please refer to our <Link href={configuration.biometric_policy_url} underline='hover' target="_blank" rel="noopener">Biometric Information Privacy Policy </Link></span>
                                                        <span>or contact your school or member organisation for further information.  </span>
                                                        <span>You can unsubscribe or adjust your settings at any time after registering, by going to My Account.</span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </InfoContainer>

                                    </CardContent>
                                </Card>
                                {!props.isInvite ?
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={configuration.recaptcha_site_key!}
                                            onChange={onSubmit}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                                <Footer
                                    showPrev={props.pageInfo.prevPageRoute !== null && props.pageInfo.prevPageRoute !== undefined}
                                    onPrev={onClickPrevious}
                                    nextText={props.pageInfo.isFinalPage ? "Finish" : "Next"}
                                    disabled={isSubmitting || !isValid || (!props.isInvite && !recaptchaToken)}
                                    type="submit"
                                />
                            </form>
                        )}
                    </Formik>
                </div>
            )}
        </Container>
    )

}