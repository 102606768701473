import styles from './home.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTheme } from '@mui/material/styles';


const Home = () => {
    const theme = useTheme();

    return (
        <div>
            <div className={styles.Home}>
                <div className={styles.HomeContent} style={{ paddingTop: "20vh" }} >

                    <FontAwesomeIcon icon={light("arrow-progress")} color={theme.palette.primary.main} size="10x" />
                    {/* <RouteOutlined color="primary" sx={{ fontSize:"12em" }} /> */}

                    <h1 className={styles.titleMuted}> ... </h1>

                </div>
            </div>
        </div>
    )
};

export default Home;