
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.9rem', color: 'white' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: '#373f49',
	color: 'white'

}));

export default AccordionSummary;