const configuration = {
    api_url: process.env.REACT_APP_API_URL,
    api_key: process.env.REACT_APP_API_KEY,
    core_api_url: process.env.REACT_APP_CORE_API_URL,
    core_api_key: process.env.REACT_APP_CORE_API_KEY,
    portal_url: process.env.REACT_APP_PORTAL_URL,
    company_suffix: process.env.REACT_APP_COMPANY_SUFFIX, // Curently not used.  TODO: Use full company name if nessessary.
    terms_url: process.env.REACT_APP_TERMS_URL,
    privacy_url: process.env.REACT_APP_PRIVACY_URL,
    biometric_policy_url: process.env.REACT_APP_BIOMETRIC_POLICY_URL,
    recaptcha_site_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    biometric_privacy_url: process.env.REACT_APP_BIOMETRIC_PRIVACY_URL,
    media_consent_privacy_url: process.env.REACT_APP_MEDIA_CONSENT_PRIVACY_URL
  };

export default configuration;
