import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ConsentQuestion, ConsentQuestionOption } from "../../../models/consent";
import React from "react";

interface ConsentQuestionSelectorProps {
    /**
     * The consent question.
     */
    question: ConsentQuestion;

    /**
     * The array of options for the consent question.
     */
    options: ConsentQuestionOption[];

    /**
     * The callback to execute when the selector is changed.
     */
    onChange: (value: number) => void;
}

const ConsentQuestionSelector = (props: ConsentQuestionSelectorProps) => {
    const [consentValue, setConsentValue] = React.useState<number>(props.question.value);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as number;
        setConsentValue(value);
        props.onChange(value);
    };

    let description = props.question.description;

    if (props.question.additionalDescription) {
        description += ` (${props.question.additionalDescription})`;
    }

    return (
        <Box sx={{ m: 3 }}>
            <Typography>{description}</Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel>{props.question.name}</InputLabel>
                <Select
                    value={consentValue}
                    label={props.question.name}
                    onChange={handleChange}
                >
                    {props.options.map((option, index) =>
                        <MenuItem key={index} value={option.id}>{option.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormHelperText>{props.options.find(x => x.id === consentValue)?.description}</FormHelperText>
        </Box>
    );
}

export default ConsentQuestionSelector;