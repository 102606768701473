import styled from "styled-components";

export const Container = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    padding-top: 10vh;
`;

export const VerifyCodeContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    background-color: #fff;
`;

export const VerifyCodeInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    background-color: #fff;
    min-width: 250px;
    max-width: 600px;
`;
