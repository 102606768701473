import React from "react";
import { Backdrop, CircularProgress } from '@mui/material';

interface LoadingWheelProps {
	isLoading: boolean;
}


export const Loading = (props: LoadingWheelProps) => {
	const { isLoading } = props;

	return (
		<Backdrop style={{ zIndex: 1000 }} open={isLoading}>
			<CircularProgress sx={{ color: "primary.contrastText" }} size={100} />
		</Backdrop>
	);
}
