import * as Yup from 'yup';
import { VALID_NAME_REGEX, VALID_PASSWORD, VALID_PHONE } from '../constants/regex';

export const registrationSchema = Yup.object().shape({
    firstName: Yup
        .string()
        .max(40, "Maximum length is 40 characters")
        .required('First Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
    lastName: Yup
        .string()
        .max(45, "Maximum length is 45 characters")
        .required('Last Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
    password: Yup
        .string()
        .required('Password is required')
        .matches(VALID_PASSWORD, { message: "Password must meet minimum requirements" }),
    confirmPassword: Yup
        .string()
        .required('Password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match')
        .matches(VALID_PASSWORD, { message: "Password must meet minimum requirements" }),
});


export const pixUserRegistrationSchema = Yup.object().shape({
    firstName: Yup
        .string()
        .max(40, "Maximum length is 40 characters")
        .required('First Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
    lastName: Yup
        .string()
        .max(45, "Maximum length is 45 characters")
        .required('Last Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
});


export const newRegistrationSchema = Yup.object().shape({
    firstName: Yup
        .string()
        .max(40, "Maximum length is 40 characters")
        .required('First Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
    lastName: Yup
        .string()
        .max(45, "Maximum length is 45 characters")
        .required('Last Name is Required')
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" }),
    email: Yup
        .string()
        .email('Must be a Valid Email Address')
        .required('Email Address is Required'),
    confirmEmail: Yup
        .string()
        .email('Must be a Valid Email Address')
        .required('Confirm Email Address is Required')
        .test('email-match', 'Email does not match', function(value) {
            return this.parent.email.toLowerCase() === value.toLowerCase();
        }),
        //.oneOf([Yup.ref('email').toLowerCase()], 'Email does not match'),
    password: Yup
        .string()
        .required('Password is required')
        .matches(VALID_PASSWORD, { message: "Password must meet minimum requirements" }),
    confirmPassword: Yup
        .string()
        .required('Password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match')
        .matches(VALID_PASSWORD, { message: "Password must meet minimum requirements" }),
});
