import Box from '@mui/material/Box';
import { Button, ButtonProps } from '@mui/material';

interface FooterProps extends ButtonProps {

    /**
     * Text for the 'Next' button. Defaults to 'Next'.
     */
    nextText?: string;
    /**
     * Whether to show the 'Previous' button.
     */
    showPrev: boolean;


    /**
     * Function to handle clicking the 'Next' button.
     */
    onNext?: () => void;

    /**
     * Function to handle clicking the 'Previous' button.
     */
    onPrev?: () => void;

    /**
     * 
     */
}

/**
 * Renders a footer with 'Previous' and 'Next' buttons.
 */
const Footer: React.FC<FooterProps> = ({nextText, showPrev, onNext, onPrev,  ...props}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent:  showPrev ? 'space-between' : 'center', mb: 5, mt: 2 }}>
            { showPrev ? 
                <Button sx={{ marginRight: 10}} onClick={onPrev} variant='outlined'>
                    Previous
                </Button> :
                <></>
            }
            
            <Button onClick={onNext} variant='outlined' {...props}>
                {nextText ? nextText : 'Next'}
            </Button>
        </Box>
    );
};

export default Footer;