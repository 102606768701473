import styled from "styled-components";

export const PixSpan = styled.span`
  color: rgba(1,157,160,1)
`
export const Container = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
`