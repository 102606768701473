import { ApiHandler } from "./axios-client";
import configuration from '../config';
import { SendTotpResponse, LoginTotpResponse, AuthTokens, InviteDetails, FormComponent } from "../models";
import { RegistrationRequest, RegistrationInfoResponse, RegistrationInviteRequest } from "../models/registration";
import { FormPageResponse } from "../models/formPageResponse";
import { ConsentDetailsResponse, UpdateConsentDetailsRequest } from "../models/consent";
import { FormsMultipartFileUploadRequest, SubmitUploadRequest, MultipartUploadCompleteFileDetails, PassportUploadPageData, InitialiseMultipartFileUploadResponse, FileUploadResponse } from "../models/fileUpload";
import { ApiResponse } from "../models/apiResponse";
import axios, { AxiosError } from "axios";
import { ApiErrorCode } from "../enums/ApiErrorCode";
import { SimpleComponentDetails } from "../models/form";
import { Component } from "../enums/components";
import { AppSettings } from "../models/appSettings";
import { BiometricConsentFormData, BiometricConsentRequest } from "../models/biometricConsent";


export const sendTotp = async (abortSignal: AbortSignal, inviteUid?: string | null, email?: string | null): Promise<ApiResponse<SendTotpResponse>> => {
    try {
        const request = {
            inviteUid: inviteUid,
            email: email
        };
        const url = `${configuration.api_url}auth/send-totp`;
        const response = await ApiHandler.Client.post<ApiResponse<SendTotpResponse>>(url, request, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SendTotp');
    }
}
// export const sendTotp = async (inviteUid: string, abortSignal: AbortSignal): Promise<ApiResponse<SendTotpResponse>> => {
//     try {
//         const request = {
//             inviteUid: inviteUid
//         };
//         const url = `${configuration.api_url}auth/send-totp`;
//         const response = await ApiHandler.Client.post<ApiResponse<SendTotpResponse>>(url, request, { signal: abortSignal });
//         return response.data;
//     } catch (error: any) {
//         return handleAxiosError(error, 'SendTotp');
//     }
// }


export const loginTotp = async (token: string, abortSignal: AbortSignal, inviteUid?: string | null, email?: string | null): Promise<ApiResponse<LoginTotpResponse>> => {
    try {
        const request = {
            inviteUid: inviteUid,
            email: email,
            token: token
        };
        const url = `${configuration.api_url}auth/login-totp`;
        const response = await ApiHandler.Client.post<ApiResponse<LoginTotpResponse>>(url, request, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'LoginTotp');
    }
}

// export const loginTotp = async (inviteUid: string, token: string, abortSignal: AbortSignal): Promise<ApiResponse<LoginTotpResponse>> => {
//     try {
//         const request = {
//             inviteUid: inviteUid,
//             token: token
//         };
//         const url = `${configuration.api_url}auth/login-totp`;
//         const response = await ApiHandler.Client.post<ApiResponse<LoginTotpResponse>>(url, request, { signal: abortSignal });
//         return response.data;
//     } catch (error: any) {
//         return handleAxiosError(error, 'LoginTotp');
//     }
// }

/**
 * Requests new auth tokens using the current tokens.
 * @param authTokens Tokens used to retrieve a new access and refresh token.
 */
export const refreshTokens = async (authTokens: AuthTokens): Promise<ApiResponse<LoginTotpResponse>> => {
    try {
        const url = `${configuration.api_url}auth/refresh`;
        const response = await ApiHandler.Client.post<ApiResponse<LoginTotpResponse>>(url, authTokens);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'RefreshTokens')
    }
}

export const  getInvite = async (inviteUid: string, abortSignal: AbortSignal): Promise<ApiResponse<InviteDetails>> => {
    try {
        const url = `${configuration.api_url}forms/invite/details?inviteUid=${inviteUid}`;
        const response = await ApiHandler.Client.get<ApiResponse<InviteDetails>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetInvite');
    }
}

export const getInviteComponent = async (inviteUid: string, componentId: number, abortSignal: AbortSignal): Promise<ApiResponse<SimpleComponentDetails>> => {
    try {
        const url = `${configuration.api_url}forms/invite/component?inviteUid=${inviteUid}&componentId=${componentId}`;
        const response = await ApiHandler.Client.get<ApiResponse<SimpleComponentDetails>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetInviteComponent');
    }
}

/**
 * Returns the details for a form along with the initial route.
 * @param inviteUid The inviteUid to look up.
 * @param abortSignal 
 * @returns 
 */
export const getForm = async (formId: string, abortSignal: AbortSignal): Promise<ApiResponse<FormComponent>> => {
    try {
        const url = `${configuration.api_url}form?formId=${formId}`;
        const response = await ApiHandler.Client.get<ApiResponse<FormComponent>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetInvite');
    }
}


/**
 * Returns the email address for a given inviteUid.
 * @param inviteUid The inviteUid to look up.
 * @param abortSignal 
 * @returns 
 */
export const getComponent = async (formId: number, component: Component, abortSignal: AbortSignal): Promise<ApiResponse<SimpleComponentDetails>> => {
    try {
        const url = `${configuration.api_url}form/component?formId=${formId}&componentId=${component}`;
        const response = await ApiHandler.Client.get<ApiResponse<SimpleComponentDetails>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetInvite');
    }
}

/**
 * Retrieve registration details for the specified inviteUid.
 * @param inviteUid The invite Uid to retrieve consent details for.
 */
export const getInviteRegistrationDataAsync = async (inviteUid: string, abortSignal: AbortSignal): Promise<ApiResponse<FormPageResponse<RegistrationInfoResponse>>> => {
    try {
        const url = `${configuration.api_url}invite/registration?inviteUid=${inviteUid}`;
        const response = await ApiHandler.Client.get<ApiResponse<FormPageResponse<RegistrationInfoResponse>>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetInviteRegistrationDataAsync');
    }
};

/**
 * Retrieve registration details for the specified inviteUid.
 * @param inviteUid The invite Uid to retrieve consent details for.
 */
export const getRegistrationDataAsync = async (abortSignal: AbortSignal): Promise<ApiResponse<AppSettings>> => {
    try {
        const url = `${configuration.api_url}registration`;
        const response = await ApiHandler.Client.get<ApiResponse<AppSettings>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetRegistrationDataAsync');
    }
};

/**
 * Submits the registration details to the API.
 * @param registrationRequest The registration to be submitted.
 */
export const submitUserRegistrationAsync = async (registrationRequest: RegistrationInviteRequest): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}invite/registration`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, registrationRequest);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SubmitUserRegistrationAsync');
    }
};


/**
 * Submits the registration details for the anonymous registration page to the API.
 * @param registrationRequest The registration to be submitted.
 */
export const submitRegistrationAsync = async (registrationRequest: RegistrationRequest): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}registration`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, registrationRequest);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SubmitUserRegistrationAsync');
    }
};

/**
 * Submits a request to validate a reCAPTCHA token before an anonymous registration.
 * @param token The recaptcha token to verify.
 */
export const validateRegistrationRequest = async (token: string, emailAddress: string): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}token/verify`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, { token: token, emailAddress: emailAddress });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'Verify registration request');
    }
};

/**
 * Retrieve consent details for the specified inviteUid.
 * @param inviteUid The invite Uid to retrieve consent details for.
 */
export const getConsentDetailsAsync = async (inviteUid: string, abortSignal: AbortSignal): Promise<ApiResponse<FormPageResponse<ConsentDetailsResponse>>> => {
    let url = '';
    try {
        url = `${configuration.api_url}consent?inviteUid=${inviteUid}`;
        const response = await ApiHandler.Client.get<ApiResponse<FormPageResponse<ConsentDetailsResponse>>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetConsentDetailsAsync');
    }
}

/**
 * Submits the consent details to the API.
 * @param updateConsentRequest The consent details to be submitted.
 */
export const submitConsentDetailsAsync = async (updateConsentRequest: UpdateConsentDetailsRequest, abortSignal: AbortSignal): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}consent`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, updateConsentRequest, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SubmitConsentDetailsAsync');
    }
};



/**
 * Retrieve passport upload details for the specified inviteUid.
 * @param inviteUid The invite Uid to retrieve consent details for.
 */
export const getPassportPageDataAsync = async (inviteUid: string, abortSignal: AbortSignal): Promise<ApiResponse<FormPageResponse<PassportUploadPageData>>> => {
    try {
        const url = `${configuration.api_url}passport?inviteUid=${inviteUid}`;
        const response = await ApiHandler.Client.get<ApiResponse<FormPageResponse<PassportUploadPageData>>>(url, { signal: abortSignal });
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetPassportPageDataAsync');
    }
}

/**
 * Submit request for initiating a multipart upload for a single file.
 * @param request The request to initiate the multipart upload.  
 */
export const initiateMultipartUploadAsyncAsync = async (request: FormsMultipartFileUploadRequest): Promise<ApiResponse<InitialiseMultipartFileUploadResponse>> => {
    try {
        const url = `${configuration.api_url}passport/initiate-multipart`;
        const response = await ApiHandler.Client.post<ApiResponse<InitialiseMultipartFileUploadResponse>>(url, request);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'InitiateMultipartUploadAsyncAsync');
    }
    // const url = `https://localhost:44324/api/v1.0/passport-upload/create-multipart`
    //     var result = await ApiHandler.Client.post<MultipartRequestResult>(url, payload, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-api-key': 'forms-secret'
    //         }
    //     });
    //     return result;
}

/**
 * Submit request for finalising a multipart upload for a single file.
 * @param request The request to finalise the multipart upload.  
 */
export const finaliseMultipartUploadAsync = async (request: MultipartUploadCompleteFileDetails): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}passport/finalise-multipart`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, request);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'FinaliseMultipartUploadAsync');
    }
    // const url = `https://localhost:44324/api/v1.0/passport-upload/finalise-multipart`
    // var result = await ApiHandler.Client.post<any>(url, payload, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'x-api-key': 'forms-secret'
    //     }
    // });
    // return result;
}

/**
 * Submit request for submitting the validated and uploaded files to pixevety.
 * @param request The request to submit the uploaded files.  
 */
export const submitPassportImagesAsync = async (request: SubmitUploadRequest): Promise<ApiResponse<FileUploadResponse>> => {
    try {
        const url = `${configuration.api_url}passport/submit-passport-images`;
        const response = await ApiHandler.Client.post<ApiResponse<FileUploadResponse>>(url, request);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SubmitPassportImagesAsync');
    }
}

/**
 * 
 * @param inviteUid Get the form data required to display the Biometric consent page.
 * @param abortSignal 
 * @returns 
 */
export const getBiometricConsentDetailsAsync = async (inviteUid: string, abortSignal: AbortSignal) => {
    try {
        const url = `${configuration.api_url}biometricconsent?inviteUid=${inviteUid}`;
        const response = await ApiHandler.Client.get<ApiResponse<BiometricConsentFormData>>(url, { signal: abortSignal});
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'GetBiometricConsentDetails');
    }
} 

/**
 * 
 * @param request Submit a Biometric consent requets to the forms API.
 * @returns 
 */
export const submitBiometricConsentAsync = async (request: BiometricConsentRequest): Promise<ApiResponse> => {
    try {
        const url = `${configuration.api_url}biometricconsent`;
        const response = await ApiHandler.Client.post<ApiResponse>(url, request);
        return response.data;
    } catch (error: any) {
        return handleAxiosError(error, 'SubmitBiometricConsent');
    }
} 

const handleAxiosError = (error: any, errorText: string) => {
    console.error(errorText, error);
    const axiosError = error as AxiosError<ApiResponse>;
    if (axiosError.response) {
        return axiosError.response.data;
    } else {
        const response: ApiResponse = {
            errorCode: ApiErrorCode.FatalError,
            success: false,
            cancelled: axios.isCancel(error)
        };
        return response;
    }
}
