import { Box, Skeleton } from "@mui/material";

const ConsentPlaceholder = (props: any) => {

    const buildPaceholderContent = (lines: number) => {
        let content = [];
        for (let i = 0; i < lines; i++) {
            content.push(
                <Box key={i} sx={{ m: 3 }}>
                    <Skeleton variant='rectangular' style={{ marginBottom: 10, width: '100%', height: 25, minWidth: 120 }} />
                    <Skeleton variant='rectangular' height={45} sx={{ mb: 1 }} style={{ width: '100%', minWidth: 50 }} />
                    <Skeleton variant='rectangular' style={{ marginBottom: 10, width: '100%', height: 15, minWidth: 120 }} />
                </Box>
            )
        }
        return content;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap-reverse', maxWidth: 1200, flexDirection: 'column' }}>
            {buildPaceholderContent(props.lines)}
        </div>
    );
}

export default ConsentPlaceholder;