import { AuthTokens } from "../models";

export const getStoredTokens = (): AuthTokens | null => {
    try {
        const lsTokensStr = sessionStorage.getItem("tokens");
        if (lsTokensStr && lsTokensStr.length > 0) {
            return JSON.parse(lsTokensStr);
        }
    } catch (error: any) {
        console.error('Error getting tokens', error);
    }
    return null;
}

export const setStoredTokens = (authTokens: AuthTokens): void => {
    if (authTokens) {
        try {
            sessionStorage.setItem("tokens", JSON.stringify(authTokens));
        } catch (error: any) {
            console.error('Error saving tokens', error);
        }
    }
}

export const removeStoredTokens = (): void => {
    try {
        sessionStorage.removeItem("tokens");
    } catch (error: any) {
        console.error('Error removing tokens', error);
    }
}


/**
 * Store the current formId in sessionStorage.  Allows reloading the form after a hard refresh.
 * @param formId 
 */
export const setFormId = (formId: number): void => {
    try {
        sessionStorage.setItem("formId", JSON.stringify(formId));
    } catch (error: any) {
        console.error('Error saving formId', error);
    }
}

/**
 * Retrieve the currently stored formId from local storage.
 * @returns 
 */
export const getFormId = (): number | null => {
    try {
        var formIdString = sessionStorage.getItem("formId");
        if (formIdString) {
            return parseInt(formIdString);
        }

    } catch (error: any) {
        console.error('Error getting formId', error);
    }
    return null;
}

/**
 * Remove the currently stored formId from local storage.
 */
export const removeFormId = (): void => {
    try {
        sessionStorage.removeItem("formId");
    } catch (error: any) {
        console.error('Error removing formId', error);
    }
}

/**
 * Store the current inviteUid in sessionStorage.  Allows reloading the form after a hard refresh.
 * @param formId 
 */
export const setInviteUId = (uId: string): void => {
    try {
        sessionStorage.setItem("inviteUid", uId);
    } catch (error: any) {
        console.error('Error saving inviteUid', error);
    }
}

/**
 * Retrieve the currently stored inviteUid from local storage.
 * @returns 
 */
export const getInviteUId = (): string | null => {
    try {
        var inviteUid = sessionStorage.getItem("inviteUid");
        if (inviteUid) {
            return inviteUid;
        }

    } catch (error: any) {
        console.error('Error getting inviteUid', error);
    }
    return null;
}

/**
 * Remove the currently stored formId from local storage.
 */
export const removeInviteUId = (): void => {
    try {
        sessionStorage.removeItem("inviteUid");
    } catch (error: any) {
        console.error('Error removing inviteUid', error);
    }
}


/**
 * Store the current component rout in sessionStorage.  Allows reloading a page that requires authorisation after a hard refresh.
 * @param formId 
 */
export const setCurrentRoute = (route: string): void => {
    try {
        sessionStorage.setItem("currentRoute", route);
    } catch (error: any) {
        console.error('Error saving route', error);
    }
}

/**
 * Retrieve the currently stored inviteUid from local storage.
 * @returns 
 */
export const getCurrentRoute = (): string | null => {
    try {
        var route = sessionStorage.getItem("currentRoute");
        if (route) {
            return route;
        }

    } catch (error: any) {
        console.error('Error getting currentRoute', error);
    }
    return null;
}

/**
 * Remove the currently stored formId from local storage.
 */
export const removeCurrentRoute = (): void => {
    try {
        sessionStorage.removeItem("currentRoute");
    } catch (error: any) {
        console.error('Error removing currentRoute', error);
    }
}

