import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AuthTokens } from '../models';
import { refreshTokens } from './forms-api-service';
import { setStoredTokens, getStoredTokens } from './local-storage';

class ApiHandler {
    Client: AxiosInstance;

    constructor() {
        // Create axios client.  base url is defined following construction
        this.Client = axios.create({
            timeout: 10000,
            headers: {
                Accept: 'application/json'
            }
        });

        this.initialiseInterceptors();
    }


    initialiseClient(logoutCallback: () => void) {
        this.logoutCallback = logoutCallback;
    }


    //
    // private members
    //

    private logoutCallback() { }

    private initialiseInterceptors() {

        this.Client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
            if (config.headers) {
                const tokens = getStoredTokens();
                if (tokens && tokens?.accessToken !== null) {
                    config.headers.Authorization = `Bearer ${tokens!.accessToken}`;
                }

                // config.headers['X-Api-Key'] = oidcConfiguration.api_key;
                config.headers['Content-Type'] = 'application/json';
            }

            return config;
        })

        //Handle the refresh token
        this.Client.interceptors.response.use(
            async (response: AxiosResponse) => response,
            async (err: AxiosError) => {
                if (err.config) {
                    const originalReq = err.config;

                    if (err.response) {
                        //When response comes back as invalid token, the status type is not always 401, but 404.  For now this means the below check for 'invalid_token' is required.

                        if (err.response.status === 403 && err.config.url?.endsWith("/auth/refresh")) {
                            // this will be a 403 but restrict to url endpoint as well tio make sure!
                            // refresh token failed!
                            this.logoutCallback();

                            // how do we logout here??  the page stays on the last failed...
                            return Promise.reject(err);
                        }

                        // if (!originalReq._retry && (err.response.status === 401 || (err.response.headers && err.response.headers['www-authenticate'] && err.response.headers['www-authenticate'].includes('invalid_token')))) {
                        if (err.response.status === 401 || (err.response.headers && err.response.headers['www-authenticate'] && err.response.headers['www-authenticate'].includes('invalid_token'))) {

                            const authTokens = getStoredTokens();
                            if (authTokens) {
                                //console.log("******* REFRESHING AUTH TOKEN ***********");
                                const refreshResponse = await refreshTokens(authTokens);
                                // NOTE!! it will not return here if it fails!!!  it will come back through the interceptor as an error!
                                //		  could this cause a memory leak or threading problems? 

                                if (refreshResponse.data?.accessToken && refreshResponse.data?.refreshToken) {
                                    const authTokens: AuthTokens = {
                                        accessToken: refreshResponse.data.accessToken,
                                        refreshToken: refreshResponse.data.refreshToken
                                    }
                                    setStoredTokens(authTokens);

                                    originalReq.headers.Authorization = 'Bearer ' + refreshResponse.data.accessToken;
                                    const result = await this.Client.request(originalReq);
                                    return result;
                                }
                            } else {
                                console.log("No tokens for refresh!");
                            }
                        }

                        // return Error object with Promise
                        return Promise.reject(err);
                    }
                }

                return Promise.reject(err);
            }
        );
    }
}

const handler = new ApiHandler();

export { handler as ApiHandler };