import { Typography } from '@mui/material';
import { Container } from './style';


const PageNotFound = () => {

  return (
    <Container>
      <Typography gutterBottom variant="h3" component="div" textAlign="center" color="primary" >
        Page not found
      </Typography>

      <Typography gutterBottom variant="h6" component="div" textAlign="center" fontWeight='fontWeightLight' >
        We can't seem to find the page you're looking for.
      </Typography>
      {/* <Typography gutterBottom variant="body1" component="div" textAlign="center" fontWeight='fontWeightLight' >
        The URL may be misspelled or the page you're looking for is no longer available.
      </Typography> */}
    </Container>
  )
};

export default PageNotFound;