
import * as Yup from 'yup';
import { VALID_NAME_REGEX } from '../constants/regex';


export const biometricConsentValidationSchema = Yup.object().shape({
    providerRelationship: Yup
        .string()
        .matches(VALID_NAME_REGEX, { message: "Invalid characters" })
});


