import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormInfo } from "../models/formInfo";
import { getFormId, getInviteUId, getStoredTokens, setFormId, setInviteUId } from "../services/local-storage";
import { hasTokens } from "../Utils/jwtUtils";
import { RegistrationInfo } from "../models/registration";
import { getInvite } from "../services/forms-api-service";
import { ApiErrorCode } from "../enums/ApiErrorCode";
import { ErrorPageTitle } from "../components/constants/errorMessages";
import { ErrorDetail } from "../components/error/error";

export interface FormState {
	formInfo: FormInfo;
	registrationInfo: RegistrationInfo;
	setFormInfo: React.Dispatch<React.SetStateAction<FormInfo>>;//(value: FormInfo) => void;
	setRegistrationInfo: React.Dispatch<React.SetStateAction<RegistrationInfo>>;
}

const FormContext = createContext<any>(undefined);

/**
 * Context provider for information relating to the current form
 * @param param0 
 * @returns  
 */
export const FormContextProvider = ({ children }: any) => {
	const navigate = useNavigate();

	const [formInfo, setFormInfo] = useState<FormInfo>({ formId: null, inviteUid: "", formName: "", personName: "", galleryName: "" });
	const [registrationInfo, setRegistrationInfo] = useState<RegistrationInfo>({registrationRequest: null , totpEmailAddress: null, complete: false})


	const abortController = new AbortController();
	const abortSignal = abortController.signal;

	// Get the queryParams from the url.
	const search = useLocation().search;
	const uidParam = new URLSearchParams(search).get('uid');
	const fidParam = new URLSearchParams(search).get('fId');

	useEffect(() => {
		//console.log("Initialising Form Context.....");

		var formId: number | null = null;

		if (fidParam) {
			formId = parseInt(fidParam, 10);

			//Save the formId to local storage to handle page refresh.
			setFormId(formId);
		}

		if (uidParam) {
			//Save the inviteUid to local storage to handle page refresh.
			setInviteUId(uidParam)
		}

		//Store in current context
		setFormInfo(prevFormInfo => ({
			...prevFormInfo,
			formId: formId,
			inviteUid: uidParam
		}));

	}, []);

	/**
	 *   Handle reseting the formInfo from seesion storage on page refresh when the queryparams are not available
	 */
	useEffect(() => {

		if (!uidParam && !fidParam) {
			var formId = getFormId();
			var inviteUid = getInviteUId();

			setFormInfo(prevFormInfo => ({
				...prevFormInfo,
				formId: formId,
				inviteUid: inviteUid
			}));
		}

	}, []);



	/**
	 * If the User is authorised (has an access token in session storage), attempt to get the form details specific to an invite.
	 * This will only be called when the form is hard refreshed.
	 */
	useEffect(() => {
		const getInviteDetails = async (isAuthorised: boolean, inviteUid: string) => {
			if (isAuthorised) {
				var inviteDetails = await getInvite(inviteUid, abortSignal)
				if (inviteDetails.success) {
					setFormInfo(prevFormInfo => ({
						...prevFormInfo,
						formId: inviteDetails.data?.formId,
						formName: inviteDetails.data?.formName,
						personName: inviteDetails.data?.personName,
						galleryName: inviteDetails.data?.galleryName
					}));
				} else if (inviteDetails.errorCode === ApiErrorCode.GalleryFormDisabled) {
					navigate('/error', { state: { message: inviteDetails.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail })
				} else {
					navigate('/error');
				}
			}	
		}

		var isAuthorised = hasTokens(getStoredTokens());

		let inviteUid = uidParam ?? getInviteUId();
		//console.log(`get invite details.  IsAuthorised: ${isAuthorised}.  InviteUid: ${inviteUid}`);
		
		if (isAuthorised && inviteUid && inviteUid.length > 0) {
			getInviteDetails(isAuthorised, inviteUid)
				.catch(error => {
					console.log(error);
					navigate('/error');
				});
		}

	}, [])

	const formContext: FormState = {
		formInfo,
		registrationInfo,
		setFormInfo,
		setRegistrationInfo
	};


	return (
		<>
			<FormContext.Provider value={formContext}>
				{children}
			</FormContext.Provider>
		</>
	);
};

export default FormContext;