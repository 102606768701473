import { useContext, useEffect, useState } from "react";
import { DeleteIconContainer, ImageContainer, ImageContainerContent, ImageContainerRow, StyledImage, ImageIconContainer, StyledSkeleton } from "./style"
import { IconButton, InputLabel, CircularProgress, Card, CardContent, Typography, useMediaQuery, Divider, Link } from "@mui/material";
import { AddCircle, DeleteForeverOutlined, Error, Feedback } from '@mui/icons-material';
import { ImageUploadItem } from "./imageUploadItem";
import { FormsMultipartFileUploadRequest, PassportUploadPageData, SubmitUploadRequest } from "../../models/fileUpload";
import AuthContext, { Auth } from "../../contexts/authContext";
import FormContext, { FormState } from "../../contexts/formContext";
import { initiateMultipartUploadAsyncAsync, submitPassportImagesAsync, getPassportPageDataAsync } from "../../services/forms-api-service";
import CircularProgressWithLabel from "../shared/circularProgressWithLabel";
import { FormPageResponse, PageInfo } from "../../models/formPageResponse";
import { useNavigate } from "react-router-dom";
import { Loading } from "../shared/loadingWheelOverlay";
import SnackMessage, { SnackMessageContent } from "../shared/snackMessage";
import Footer from "../footer/footer";
import { FileUploadStatus } from "../../enums/fileUploadStatus";
import { ErrorDetail } from "../error/error";
import { ApiResponse } from "../../models/apiResponse";
import { LoadingState } from "../../models/loadingState";
import { Container } from "../shared/styledComponents";
import configuration from "../../config";
import { ApiErrorCode } from "../../enums/ApiErrorCode";
import { ErrorPageTitle } from "../constants/errorMessages";
import { Routes } from "../../enums/routes";
import { Checkmark } from "../shared/animatedCheckmark/checkmark";

const PassportUpload = () => {

    const navigate = useNavigate();

    const { user } = useContext<Auth>(AuthContext);
    const { formInfo: { personName }, setFormInfo } = useContext<FormState>(FormContext);

    const [imageUploadItems, setImageUploadItems] = useState<Array<ImageUploadItem | null>>([null, null, null]);
    const [pageData, setPageData] = useState<FormPageResponse<PassportUploadPageData>>();
    const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
    const [isLoading, setLoading] = useState<LoadingState>({ showSpinner: false, showSkeleton: true });
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<SnackMessageContent>({ message: '', messageLevel: 'error' });
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    const abortController = new AbortController();
    const abortSignal = abortController.signal;


    /**
     * Set the current page route so the error page can return here on error.
     */
    useEffect(() => {
        setFormInfo(prevFormInfo => ({
            ...prevFormInfo,
            currentPageRoute: Routes.PassportUpload
        }));
    }, [])

    /**
     * Load the pageData when the inviteUid has been loaded in the AuthContext.
     * This will usualy be available when the component first renders, except when there is a hard refresh.
     */
    useEffect(() => {
        if (user?.inviteUid && user.inviteUid.length > 0) {
            getPageData();
        }
        
        return () => { abortController.abort() };

    }, [user?.inviteUid]);

    const getPageData = async () => {
        try {
            
            //TODO: Show skeleton of page while loading page data.
            setLoading({ ...isLoading, showSkeleton: true });
            const response = await getPassportPageDataAsync(user!.inviteUid!, abortSignal);
            if (!response.success) {
                return handleError(response);
            }
            
            setPageInfo(new PageInfo(response.data?.nextPageRoute, response.data?.prevPageRoute));
            setPageData(response.data!);
            setLoading({ ...isLoading, showSkeleton: false });
        } catch (error: any) {
            console.error("ERROR loading registration info.", error);
            navigate('/error');
        }
    }

    const updateImageItemCallback = (error?: any) => {
        if (error) {
            handleError(error);
        }

        setImageUploadItems((prevImages: Array<ImageUploadItem | null>) => {
            const newImages = [...prevImages];
            return newImages
        });
    }

    const handleError = (response: ApiResponse) => {
        if (response.errorCode === ApiErrorCode.NonFatalError && !response.cancelled) {
            setSnackMessage({ message: response.errorMessage, messageLevel: 'error' });
            setSnackOpen(true);
        } else if (response.errorCode === ApiErrorCode.GalleryFormDisabled) {
            navigate('/error', { state: { message: response.errorMessage, title: ErrorPageTitle.PageNotAvailable } as ErrorDetail })
        } else {
            navigate('/error');
        }
    }

    const handleImageUpload = async (index: any, event: any) => {
        const file = event.target.files[0];
        const requestPayload: FormsMultipartFileUploadRequest = {
            inviteUid: user!.inviteUid!, // userContext.user.inviteUid,
            galleryId: pageData?.formData.galleryId!,
            fileName: file.name,
            contentLength: file.size
        }
        const fileLink = URL.createObjectURL(file);

        setLoading({ ...isLoading, showSpinner: true });

        try {
            const response = await initiateMultipartUploadAsyncAsync(requestPayload);
            setLoading({ ...isLoading, showSpinner: false });
            if (!response.success || !pageData?.formData) {
                return handleError(response);
            }

            setImageUploadItems((prevImages: Array<ImageUploadItem | null>) => {
                const newImages = [...prevImages];
                newImages[index] = new ImageUploadItem(fileLink, user!.inviteUid!, pageData.formData.galleryId, file.name, pageData.formData.albumId, response.data!);
                newImages[index]?.attachUpdateImageItem(updateImageItemCallback);
                newImages[index]?.uploadFileAsync(); //Kick off the upload
                return newImages
            });
        } catch (error: any) {
            console.error("ERROR Initialising multipart upload.", error);
            navigate('/error');
        }
    };

    /**
     * Click event handler for the delete image button.
     * @param index
     */
    const onClickDelete = (index: any) => {
        setImageUploadItems((prevImages: Array<ImageUploadItem | null>) => {
            const newImages = [...prevImages];
            newImages[index] = null;
            return newImages;
        });
    };

    /**
     * Click event handler for the next navigation button.
     */
    const onClickNext = async () => {
        const fileNames = imageUploadItems.filter(x => x && x.fileName && x.validPassportImage === true && x.status < FileUploadStatus.Complete).map(i => i?.fileName);

        if (!pageData?.formData || fileNames.length === 0) {
            // setSnackMessage({ message: "You have not selected any valid images to import.", messageLevel: 'error' });
            // setSnackOpen(true);
            navigate(pageInfo.nextPageRoute);
        } else {
            let uploadRequest: SubmitUploadRequest = {
                inviteUid: user!.inviteUid!,
                galleryUserId: pageData.formData.galleryUserId,
                galleryAssociationId: pageData.formData.galleryAssociationId,
                albumId: pageData.formData.albumId,
                fileNames: fileNames
            };

            try {
                setLoading({ ...isLoading, showSpinner: true });
                const submitResult = await submitPassportImagesAsync(uploadRequest);
                if (submitResult.success) {
                    navigate(pageInfo.nextPageRoute);
                } else if (!submitResult.success && submitResult.data?.failedItems.length) {
                    const newImages = [...imageUploadItems];
                    newImages.forEach((image: ImageUploadItem | null, index) => {
                        if (image) {
                            const failed = submitResult.data!.failedItems.find((x: string) => x === image.fileName);
                            if (failed) {
                                newImages[index]!.status = FileUploadStatus.Failed;
                                newImages[index]!.validPassportImage = false;
                                newImages[index]!.errorMessage = "An error occurred uploading this image."
                            } else {
                                newImages[index]!.status = FileUploadStatus.Complete;
                            }
                        }
                    });
                    setImageUploadItems(newImages);
                } else {
                    handleError(submitResult);
                }
            } catch (error: any) {
                console.error("Failed to submit images", error);
                navigate('/error');
            }
            setLoading({ ...isLoading, showSpinner: false });
        }
    }

    /**
     * Click handler for the previous navigation button
     */
    const onClickPrevious = () => {
        if (pageInfo.prevPageRoute) {
            navigate(pageInfo.prevPageRoute)
        }
    }

    return (
        <Container>
            <Loading isLoading={isLoading.showSpinner} />
            <SnackMessage open={snackOpen} setOpen={setSnackOpen} message={snackMessage.message} severity={snackMessage.messageLevel} />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                <h1>Set Up Face ID</h1>
                <Typography textAlign={'justify'} margin={2}>
                    A passport photo is required to identify {personName} in this Gallery and to automatically apply their photo consent.
                    <br/>To upload a <strong>portrait oriented photo</strong>, click the plus button.<br/><br/>
                    For more information, please read the <Link href={configuration.biometric_privacy_url} underline='always' target="_blank" rel="noopener">Biometric Consent Privacy Notification </Link>
                    Form or contact the Gallery owner.
                </Typography>
                <Divider light={true} sx={{ margin: "10px" }} />
            </div>

            <ImageContainerRow>
                {[0, 1, 2].map((index) => (
                    <div key={index}>
                        {isLoading.showSkeleton ?
                            <StyledSkeleton variant='rectangular' />
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ImageContainer>
                                    {imageUploadItems[index] ?
                                        <DeleteIconContainer onClick={() => onClickDelete(index)}>
                                            <DeleteForeverOutlined color="error" fontSize="large" />
                                        </DeleteIconContainer>
                                        : null
                                    }
                                    <ImageContainerContent>
                                        {imageUploadItems[index] ?
                                            <>
                                                <StyledImage src={imageUploadItems[index]?.image} alt={`Image ${index}`} />
                                                {imageUploadItems[index]?.status === FileUploadStatus.Uploading ?
                                                    <CircularProgressWithLabel value={imageUploadItems[index]?.percentComplete!} />
                                                    :
                                                    <>
                                                        {imageUploadItems[index]!.status === FileUploadStatus.Uploaded ?
                                                            <ImageIconContainer>
                                                                <CircularProgress size={100} />
                                                            </ImageIconContainer>
                                                            : //Else finalised/complete/failed
                                                            <ImageIconContainer>
                                                                {imageUploadItems[index]?.validPassportImage ?
                                                                    // <CheckCircleOutline style={{ fontSize: 100 }} color='primary' />
                                                                    <Checkmark size='large'/>
                                                                    :
                                                                    <Error style={{ fontSize: 100 }} color='error' />
                                                                }
                                                            </ImageIconContainer>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <InputLabel htmlFor={`upload-image-${index}`}>
                                                <IconButton component="span">
                                                    <AddCircle style={{ fontSize: 100 }} />
                                                </IconButton>
                                            </InputLabel>
                                        }
                                    </ImageContainerContent>

                                    <input
                                        id={`upload-image-${index}`}
                                        type="file"
                                        accept="image/jpg"
                                        //capture
                                        style={{ display: 'none' }}
                                        onChange={(event) => handleImageUpload(index, event)}
                                    />
                                </ImageContainer>
                                {imageUploadItems[index] && !imageUploadItems[index]?.validPassportImage && imageUploadItems[index]!.status > FileUploadStatus.Uploaded ?
                                    <Card sx={{ margin: 1, display: 'flex', alignItems: 'center', padding: '10px', width: isSmallScreen ? 200 : 400 }}>
                                        <Feedback sx={{ marginRight: '16px' }} color="error" />
                                        <CardContent style={{ padding: 0 }}>
                                            <Typography textAlign={'center'}>
                                                {imageUploadItems[index]?.errorMessage}
                                            </Typography>
                                        </CardContent>
                                    </Card> : null
                                }
                                {imageUploadItems[index] && imageUploadItems[index]!.status === FileUploadStatus.Complete ?
                                    <Card sx={{ margin: 1, display: 'flex', alignItems: 'center', padding: '10px', width: isSmallScreen ? 200 : 400 }}>
                                        <Feedback sx={{ marginRight: '16px' }} color="primary" />
                                        <CardContent style={{ padding: 0 }}>
                                            <Typography>
                                                Image Upload Complete.
                                            </Typography>
                                        </CardContent>
                                    </Card> : null
                                }
                            </div>
                        }
                    </div>
                ))}
            </ImageContainerRow>
            {
                !isLoading.showSkeleton ?
                    <div style={{ width: '80%' }}>
                        <Footer
                            onNext={onClickNext}
                            onPrev={onClickPrevious}
                            showPrev={pageInfo.prevPageRoute !== null && pageInfo.prevPageRoute !== undefined}
                            nextText={pageInfo.isFinalPage ? "Finish" : "Next"}
                        />
                    </div>
                    :
                    null
            }


        </Container>
    )
}

export default PassportUpload;
