import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginTotp, sendTotp } from "../services/forms-api-service";
import { AuthTokens, AuthUser, LoginTotpResponse, SendTotpResponse } from "../models";
import { ApiHandler } from "../services/axios-client";
import { getStoredTokens, removeStoredTokens, setStoredTokens } from "../services/local-storage";
import { ApiResponse } from "../models/apiResponse";
import { decodeAccessToken, hasTokens } from "../Utils/jwtUtils";


export interface Auth {
    user: AuthUser | null | undefined;
    isAuthenticated: boolean;
    //setUserEmail: (email: string) => void;
    // formInfo: FormInfo,
    logout: () => void;
    // setFormInfo: (value: FormInfo) => void,
    sendTOTP: (abortSignal: AbortSignal, inviteUid? : string | null | undefined, email?: string | null | undefined) => Promise<ApiResponse<SendTotpResponse>>;
    loginTOTP: (token: string, abortSignal: AbortSignal, inviteUid?: string | null | undefined, email?: string | null | undefined) => Promise<ApiResponse<LoginTotpResponse>>;
}

const AuthContext = createContext<any>(undefined);

export const AuthContextProvider = ({ children }: any) => {
    //TODO: Pull these models out into 1 model and create a reducer to update the values.
    const [user, setUser] = useState<AuthUser | null | undefined>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("[USER] Updated: ", user)
    // }, [user])

    useEffect(() => {
        //console.log("Initialising.....");
        // re-initialise the api
        // Dont know if this is the right place to do this.
        ApiHandler.initialiseClient(logout);

        const tokens = getStoredTokens();
        if (tokens) {

            //If we have a token in local storage, update the user object with the info in the token.
            const tokensFound = hasTokens(tokens);

            if (tokensFound) {
                // This is likely a page refresh (F5) - if so there will be no user object yet... so reload it
                const authUser = decodeAccessToken(tokens.accessToken);

                //Dont know if we should be doing this.  Investigate and re-implement
                // if (uidParam && authUser?.inviteUid !== uidParam) {
                //     // The inviteUid and stored token inviteUid are different, force logout
                //     clearTokens();
                //     return navigate(`/login?uid=${uidParam}`);
                // }

                if (!user) {
                    setUser(authUser);
                }
                setIsAuthenticated(true);
            }
        } else {
            // No tokens found in session storage
            // Dont know if we still need to do this as tokens have been moved to session storage.
            clearTokens();
        }
    }, [])


    const sendTOTP = async (abortSignal: AbortSignal, inviteUid?: string | null | undefined, email?: string | null | undefined): Promise<ApiResponse<SendTotpResponse>> => {

        setUser({email: email, inviteUid: inviteUid})

        const response = await sendTotp(abortSignal, inviteUid, email);

        if (!response.success && !response.data?.sent) {
            clearTokens();
        }

        return response
    };


    const loginTOTP = async (token: string, abortSignal: AbortSignal, inviteUid?: string | null | undefined, email?: string | null | undefined): Promise<ApiResponse<LoginTotpResponse>> => {
        const response = await loginTotp(token, abortSignal, inviteUid, email);
        if (!response.success || !response.data) {
            console.error('Login failed! Invalid TOTP', response);
            return response;
        }

        // verify tokens received!
        if (response.data.accessToken && response.data.refreshToken) {
            const authTokens: AuthTokens = {
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken
            };
            setStoredTokens(authTokens);

            // update state
            const authUser = decodeAccessToken(authTokens.accessToken);

            //console.log("Decoded AuthUser: ", authUser);
            setUser(authUser);

            const isAuthenticated = hasTokens(authTokens);
            setIsAuthenticated(isAuthenticated);
        }
        return response;
    }

    const logout = () => {
        clearTokens();
        navigate("/");
    };

    const clearTokens = () => {
        // clear auth tokens
        removeStoredTokens();
        // clear state
        setUser(null);
        setIsAuthenticated(false);
    };

    // //TODO: Remove this if we dont en up using it.
    // const setUserEmail = (email: string) => {
    //     setUser(prevUser => ({
    //         ...prevUser,
    //         email: email
    //     }));
    // }

    const authContext: Auth = {
        user,
        isAuthenticated,
        //setUserEmail,
        sendTOTP,
        loginTOTP,
        logout    
    };

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;