import { Container } from "./style";
import { Button } from '@mui/material';
import { isMobile } from "../../Utils/browserUtils";
import { Checkmark } from "../shared/animatedCheckmark/checkmark";
import configuration from '../../config';


const FormComplete = () => {

    // useEffect(() => {
    //     console.log(navigator.userAgent)
    // });

    const login = () => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 1100) return;
            window.location.href = configuration.portal_url ?? window.location.href;
        }, 1000)
        window.location.href = 'pixevetyapp://';
    }

    return (
        <Container>
            {/* <CheckCircleOutlineIcon  color="primary" style={{fontSize: isSmallScreen ? 300 : 500}}/> */}
            <Checkmark size='xxLarge'/>
            <Button style={{ marginTop: 100 }} onClick={() => {
                        if (isMobile()) {
                            login();
                        } else {
                            window.open(configuration.portal_url);
                        }
                    }} 
                    variant='outlined'>
                Login to pixevety
            </Button>
        </Container>
    )

}

export default FormComplete;